import { useEffect, useState } from 'react'

const InitFilesChannel = (realtime, appointment) => {

    const [channel, setChannel] = useState(null)

    useEffect(() => {
        if (realtime) {
            const pubChannel = realtime.channels.get(
            `filesManagement:${appointment.room}`,
            )
            setChannel(pubChannel)
        }
    }, [realtime, appointment.room])

    return channel
}

export default InitFilesChannel