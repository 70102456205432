import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'
import Logo from '../../Assets/SVG/Logo'
import getWindowDimensions from '../../Helpers/windowResize'

import styles from './WelcomeScreenStyles.module.css'

const { Title } = Typography

const urlClinica = <a target="_blank" rel="noopener noreferrer" href="https://www.clinicalascondes.cl/">www.clinicalascondes.cl</a>
const WelcomeScreen = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  const { width } = windowDimensions

  let multiplier
  if (width > 920) multiplier = 0.2
  else if (width > 450) multiplier = 0.3
  else multiplier = 0.35

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.welcomeTitle}>Bienvenido</div>
      <div className={styles.row}>
        <Logo style={{ flex: 1 }} width={width * multiplier} />
        <div className={styles.divider}>
          <div
            className={styles.horizontalSpacer}
            style={{ backgroundColor: '#E4E8F3', width: 2, height: '5vh' }}
          />
        </div>
        <h2 className={styles.title}>Telemedicina</h2>
      </div>
      <Title level={4}>
        Para solicitar hora de consulta, ingresar a la página de Clínica Las Condes {urlClinica}
      </Title>
    </div>
  )
}

export default WelcomeScreen
