import React, { useEffect } from 'react'
import { GET_APPOINTMENTS } from 'App/Queries'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

const IntercomApp = ({ children }) => {
  let token = window.location.pathname.substr(1)
  if (token.startsWith('appointment')) token = token.substr(12)
  const { error, data, loading } = useQuery(GET_APPOINTMENTS, {
    variables: { token },
  })

  const appointment = get(data, 'appointment')
  const boot = () => {
    const IntercomId = 'd6637657'
    if (appointment)
      window.Intercom('boot', {
        app_id: IntercomId,
        type: 'patient',
        name: `${appointment.patient.firstname} ${appointment.patient.lastname}`,
      })
    else
      window.Intercom('boot', {
        app_id: IntercomId,
        type: 'patient',
      })
  }

  useEffect(() => {
    if (loading) return

    if (error) console.log(error)
    else boot()
    // eslint-disable-next-line
  }, [appointment])

  return <>{children}</>
}

export default IntercomApp
