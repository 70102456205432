import * as React from 'react'

function DotsIcons(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}>
      <g>
        <g>
          <g>
            <circle cx="256" cy="256" r="64" />
            <circle cx="448" cy="256" r="64" />
            <circle cx="64" cy="256" r="64" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default DotsIcons
