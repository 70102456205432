import React from 'react'
import Logo from '../../../Assets/SVG/Logo'
import styles from '../LobbyScreenStyles.module.css'

const LobbyHeader = ({ windowDimensions }) => {
  const { width } = windowDimensions
  let multiplier
  if (width > 920) multiplier = 0.2
  else if (width > 450) multiplier = 0.3
  else multiplier = 0.2
  return (
    <div className={styles.largerRow}>
      <div className={styles.row}>
        <Logo width={width * multiplier} />
        <div className={styles.horizontalSpacer} />
        <h2 className={styles.title}>Telemedicina</h2>
      </div>
      <p className={styles.footerText}>
        Para dudas y consultas contacte al correo
        info@clinicalascondes.cl
      </p>
    </div>
  )
}

export default LobbyHeader
