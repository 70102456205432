import { notification } from 'antd'

export default (appointment, realtimeAbbly) => {
  if (appointment && realtimeAbbly) {
    const channel = realtimeAbbly.channels.get(appointment.patient._id)
    channel.subscribe('latedoctor', message => {
      notification.info({
        message: `Estimado/a ${appointment.patient.firstname} ${appointment.patient.lastname}`,
        description: `${message.data}`,
        duration: null
      })
    })
  }
}