import React, { useEffect } from 'react'
import { Spin } from 'antd'

import styles from '../LobbyScreenStyles.module.css'

const Loading = ({ realtime, appointment, history, token }) => {
  useEffect(() => {
    if (!realtime || !appointment || !history || !token) return
    const redirectToRoom = () => {
      history.push(`/appointment/${token}`)
    }

    redirectToRoom()
  }, [realtime, appointment, history, token])
  return (
    <div className={styles.loadingContainer}>
      <Spin
        className={styles.loadingWrapper}
        size="large"
        tip="Conectando a la videollamada, solo falta que ingrese tu doctor, se conectará pronto. Espera un momento."
      />
    </div>
  )
}

export default Loading
