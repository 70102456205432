import * as React from 'react'

function ConnectedStatusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.41 6.41"
      width={props.width}
      height={props.height}
      stroke={props.stroke}
      fill={props.fill}
      strokeLinecap="round"
      {...props}>
      <g transform="translate(-28.793 -321.797)">
        <line className="a" y1="5" x2="4" transform="translate(31.5 322.5)" />
        <line className="a" x1="2" y1="2" transform="translate(29.5 325.5)" />
      </g>
    </svg>
  )
}

export default ConnectedStatusIcon
