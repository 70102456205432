import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { Select, Row, Col, Spin, Modal, Tooltip, Button, Switch } from 'antd'
import * as Sentry from '@sentry/browser'
import styles from '../LobbyScreenStyles.module.css'
import { SettingsCall } from '../../../Providers'
import SoundMic from './SoundMic'
import CameraIcon from 'App/Assets/SVG/CameraIcon'
import MicIcon from 'App/Assets/SVG/MicIcon'
import DotsIcons from 'App/Assets/SVG/DotsIcons'
import AttachmentIcon from 'App/Assets/SVG/AttachmentIcon'

const TestCamera = ({
  setCanAccess,
  isMobile,
  isTourOpenTest,
  setNotTour,
  drawerOnCloseHandler,
  drawerVisible,
  setCanBeOpenTour,
  setErrorOfCameraAndAudio,
  setErrorMssge,
  setModalError,
  setCanEntryToAppointment,
  setGoToDrawer,
}) => {
  const { Option } = Select
  const videoElement = useRef(null)
  const [error, setError] = useState(false)
  const [audioInputArray, setAudioInputArray] = useState([])
  const [videoInputArray, setVideoInputArray] = useState([])
  const [videoSelect, setVideoSelect] = useState('')
  const [audioInputSelect, setAudioInputSelect] = useState('')
  const [openModalToChooseMedia, setOpenModalToChooseMedia] = useState(false)
  const [defaultVideo, setDefaultVideo] = useState('')
  const [defaultAudioInput, setDefaultAudioInput] = useState('')

  const [LocalStream, setLocalStream] = useState('')
  const [switchVideo, setSwitchVideo] = useState(false)
  const [switchMic, setSwitchMic] = useState(false)
  const settings = useContext(SettingsCall)

  useEffect(() => {
    setSwitchVideo(true)
  }, [videoInputArray])
  useEffect(() => {
    setSwitchMic(true)
  }, [audioInputArray])

  // set video in component
  const gotStream = useCallback(
    stream => {
      setLocalStream(stream)
      const video = videoElement.current
      video.srcObject = stream
      setCanAccess(true)
      return navigator.mediaDevices.enumerateDevices()
    },
    [setCanAccess],
  )

  // got devices of pc
  const gotDevices = async deviceInfos => {
    const audioInput = []
    const videoInput = []
    deviceInfos.map(item => {
      if (item.kind === 'audioinput') audioInput.push(item)

      if (item.kind === 'videoinput') videoInput.push(item)
      return true
    })
    setAudioInputArray(audioInput)
    setVideoInputArray(videoInput)
  }
  // Attach audio output device to video element using device/sink ID.

  const handleError = useCallback(
    async e => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        setErrorMssge('Tu navegador no soporta utilizar cámara y/o micrófono')
        setError(true)
        setNotTour(true)
        setModalError(true)
        setErrorOfCameraAndAudio(true)
        Sentry.captureMessage('No soporta enumerateDevices()')
        Sentry.captureException(e)
        return
      }
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.enumerateDevices() &&
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const errorMic = devices.some(
          device => device.kind === 'audiooutput' && !device.label,
        )
        const errorCam = devices.some(
          device => device.kind === 'videoinput' && !device.label,
        )
        if (!errorCam || !errorMic) setModalError(true)
        if (errorMic || errorCam) {
          setErrorMssge(
            'Por favor revisar que la cámara y/o micrófono tengan los permisos necesarios para ingresar a la videollamada. Una vez permitido su uso, refresca la ventana del navegador.',
          )
          setError(true)
          setNotTour(true)
          setModalError(true)
          setErrorOfCameraAndAudio(true)
          setCanAccess(false)
          return
        }
      })

      if (e.name !== 'NotAllowedError') {
        setErrorMssge('No se logra acceder a cámara y micrófono')
        setError(true)
        setCanAccess(false)
        setErrorOfCameraAndAudio(true)
        Sentry.captureMessage('No se logra acceder a cámara y micrófono')
        Sentry.captureException(e)
      }
      return
    },
    [
      setCanAccess,
      setErrorMssge,
      setErrorOfCameraAndAudio,
      setModalError,
      setNotTour,
    ],
  )

  const start = useCallback(async () => {
    if (!videoElement) return
    if (LocalStream)
      LocalStream.getTracks().map(track => {
        return track.stop()
      })

    const audioSource = audioInputSelect
    const videoSource = videoSelect
    const constraints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: videoSource ? { exact: videoSource } : undefined },
    }
    try {
      await navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .then(gotDevices)
      .catch(handleError)
    } catch (e) {
      handleError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioInputSelect, gotStream, handleError, videoSelect])

  const Loading = size => {
    return (
      <Col span={12} style={{ margin: 0, padding: '20px' }}>
        {' '}
        <Spin size={size} style={{ marginLeft: '50%' }} />
      </Col>
    )
  }
  // switched mic or camera
  const switchMicrophone = () => {
    if (LocalStream)
      try {
        LocalStream.getAudioTracks()[0].enabled = !switchMic
        setSwitchMic(!switchMic)
      } catch (e) {
        Sentry.captureMessage('Error can not resolve switch microphone')
        Sentry.captureException(e)
      }
  }
  const switchCamera = () => {
    if (LocalStream)
      try {
        LocalStream.getVideoTracks()[0].enabled = !switchVideo
        setSwitchVideo(!switchVideo)
      } catch (e) {
        Sentry.captureMessage('Error can not resolve switch camera')
        Sentry.captureException(e)
      }
  }

  // start
  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // video Off
  useEffect(() => {
    if (LocalStream && videoElement.current === null)
      LocalStream.getTracks().map(track => {
        track.stop()
        return true
      })
  }, [LocalStream])
  // call Start to change audio input device
  useEffect(() => {
    if (audioInputSelect !== '') start()
  }, [audioInputSelect, start])

  // change for video device
  useEffect(() => {
    if (videoSelect !== '') start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, videoSelect])

  // make mic is off in the beginning
  useEffect(() => {
    if (!switchMic && LocalStream !== '')
      LocalStream.getAudioTracks()[0].enabled = false
  }, [switchMic, LocalStream])

  // DefaultValue for Select Components
  useEffect(() => {
    if (videoInputArray && videoInputArray.length > 0) {
      setDefaultVideo(videoSelect ? videoSelect : videoInputArray[0].deviceId)
      settings.video = videoSelect ? videoSelect : videoInputArray[0].deviceId
    }
    if (audioInputArray && audioInputArray.length > 0) {
      setDefaultAudioInput(
        audioInputSelect ? audioInputSelect : audioInputArray[0].deviceId,
      )
      settings.audioInput = audioInputSelect
        ? audioInputSelect
        : audioInputArray[0].deviceId
    }
  }, [
    audioInputArray,
    audioInputSelect,
    settings.audioInput,
    settings.video,
    videoInputArray,
    videoSelect,
    settings
  ])

  useEffect(() => {
    if (defaultVideo !== '' && defaultAudioInput !== '') {
      setCanBeOpenTour(true)
      setCanEntryToAppointment(true)
    }
  }, [
    defaultAudioInput,
    defaultVideo,
    setCanBeOpenTour,
    setCanEntryToAppointment,
  ])

  return (
    <>
      <div className={styles.testCamera}>
        <div className={styles.testCameraCard}>
          {!error ? (
            <Row justify="center" style={{ width: '100%', height: '100%' }}>
              <Col span={24} style={{ width: '100%', height: '100%' }}>
                <div className={styles.videoTestContainer}>
                  <div className={styles.videoTestContent}>
                    {
                      videoElement && (
                        <video
                          ref={videoElement}
                          width="100%"
                          className={styles.videoFrame}
                          playsInline
                          autoPlay
                          muted></video>
                      )
                    }
                    {!switchVideo && (
                      <div className={styles.unactiveCam}>
                        La cámara está{' '}
                        <span style={{ fontWeight: 'bold' }}>DESACTIVADA</span>.
                      </div>
                    )}

                    <div
                      className={styles.soundDetectionContainer}
                      style={{
                        display: switchMic ? 'block' : 'none',
                      }}>
                      {switchMic && LocalStream && <SoundMic active={switchMic} LocalStream={LocalStream} />}
                    </div>
                    <div className={styles.videoControls}>
                      {isMobile ? (
                        <>
                          <div
                            className={styles.videoCircles}
                            data-tut="lobby_2"
                            style={{
                              background: !drawerVisible ? 'white' : '#EC3832',
                            }}
                            onClick={
                              !isTourOpenTest ? drawerOnCloseHandler : undefined
                            }>
                            <AttachmentIcon
                              width="26px"
                              height="26px"
                              fill={!drawerVisible ? '#6078FB' : 'white'}
                            />
                          </div>
                          <div
                            className={styles.videoCircles}
                            data-tut="lobby_3"
                            style={{
                              background: switchMic ? 'white' : '#EC3832',
                            }}
                            onClick={switchMicrophone}>
                            <MicIcon
                              width="26px"
                              height="26px"
                              fill={switchMic ? '#6078FB' : 'white'}
                            />
                          </div>
                          <div
                            className={styles.videoCircles}
                            data-tut="lobby_4"
                            style={{
                              background: switchVideo ? 'white' : '#EC3832',
                            }}
                            onClick={switchCamera}>
                            <CameraIcon
                              width="26px"
                              height="26px"
                              fill={switchVideo ? '#6078FB' : 'white'}
                            />
                          </div>
                          <div
                            className={styles.videoCircles}
                            data-tut="lobby_5"
                            style={{
                              background: 'white',
                            }}
                            onClick={() => {
                              if (
                                defaultVideo !== '' &&
                                defaultAudioInput !== '' &&
                                !isTourOpenTest
                              )
                                setOpenModalToChooseMedia(
                                  !openModalToChooseMedia,
                                )
                            }}>
                            <DotsIcons
                              width="26px"
                              height="26px"
                              fill={'#6078FB'}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Adjuntar Archivos">
                            <div
                              className={styles.videoCircles}
                              data-tut="lobby_2"
                              style={{
                                background: !drawerVisible
                                  ? 'white'
                                  : '#EC3832',
                              }}
                              onClick={
                                !isTourOpenTest
                                  ? drawerOnCloseHandler
                                  : undefined
                              }>
                              <AttachmentIcon
                                width="26px"
                                height="26px"
                                fill={!drawerVisible ? '#6078FB' : 'white'}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title="Encender/Apagar Micrófono">
                            <div
                              className={styles.videoCircles}
                              data-tut="lobby_3"
                              style={{
                                background: switchMic ? 'white' : '#EC3832',
                              }}
                              onClick={switchMicrophone}>
                              <MicIcon
                                width="26px"
                                height="26px"
                                fill={switchMic ? '#6078FB' : 'white'}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title="Encender/Apagar Cámara">
                            <div
                              className={styles.videoCircles}
                              data-tut="lobby_4"
                              style={{
                                background: switchVideo ? 'white' : '#EC3832',
                              }}
                              onClick={switchCamera}>
                              <CameraIcon
                                width="26px"
                                height="26px"
                                fill={switchVideo ? '#6078FB' : 'white'}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title="Configurar Audio o Cámara">
                            <div
                              className={styles.videoCircles}
                              data-tut="lobby_5"
                              style={{
                                background: 'white',
                              }}
                              onClick={() => {
                                if (
                                  defaultVideo !== '' &&
                                  defaultAudioInput !== '' &&
                                  !isTourOpenTest
                                )
                                  setOpenModalToChooseMedia(
                                    !openModalToChooseMedia,
                                  )
                              }}>
                              <DotsIcons
                                width="26px"
                                height="26px"
                                fill={'#6078FB'}
                              />
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="center" style={{ width: '100%', height: '100%' }}>
              <Col span={24}>
                <div className={styles.videoTestContainer}>
                  <div
                    className={styles.videoTestContent}
                    style={{ background: 'black' }}>
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        color: 'white',
                        textAlign: 'center',
                      }}>
                      Debes activar los permisos de la cámara y/o micrófono.
                      <p>
                        La cámara está{' '}
                        <span style={{ fontWeight: 'bold' }}>DESACTIVADA</span>.
                      </p>
                      <Row>
                        <Button
                          type="ghost"
                          shape="round"
                          size={'large'}
                          className={styles.startMeeting}
                          onClick={() => {
                            setModalError(true)
                          }}>
                          Ver alerta nuevamente
                        </Button>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <Modal
        title="Audio y Video"
        visible={openModalToChooseMedia}
        onOk={() => {}}
        onCancel={() => {
          setOpenModalToChooseMedia(false)
        }}
        footer={null}>
        <Row>
          <Col span={24}>
            <div className={styles.selectContainer}>
              <div className={styles.selectContentLabel}>
                <div className={styles.selectContentIcon}>
                  <div className={styles.selectIconCircle}>
                    <CameraIcon width="15px" height="15px" fill="white" />
                  </div>
                </div>
                <div style={{ width: '40%' }}>
                  <label className={styles.selectLabelTestCamera}>
                    Tú Cámara está{' '}
                    <span
                      style={{
                        color: switchVideo ? '#4CD964' : '#B2B2B2',
                        fontWeight: 'bold',
                      }}>
                      {switchVideo ? 'ON' : 'OFF'}
                    </span>
                  </label>
                </div>
                <Switch
                  style={{ background: switchVideo ? '#4CD964' : '#EAEDF0' }}
                  defaultChecked={switchVideo}
                  onChange={switchCamera}
                />
              </div>
              <div className={styles.selectContent}>
                <div style={{ width: '100%' }}>
                  {defaultVideo !== '' ? (
                    <Select
                      placeholder="Selecciona Camara"
                      defaultValue={defaultVideo}
                      style={{ width: '100%' }}
                      onChange={value => {
                        if (!switchVideo) switchCamera()
                        setVideoSelect(value)
                      }}>
                      {videoInputArray.map(device => {
                        return (
                          <Option key={device.deviceId} value={device.deviceId}>
                            {device.label}
                          </Option>
                        )
                      })}
                    </Select>
                  ) : (
                    Loading('small')
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.selectContainer}>
              <div className={styles.selectContentLabel}>
                <div className={styles.selectContentIcon}>
                  <div className={styles.selectIconCircle}>
                    <MicIcon width="15px" height="15px" fill="white" />
                  </div>
                </div>
                <div style={{ width: '40%' }}>
                  <label className={styles.selectLabelTestCamera}>
                    Tú Micrófono está{' '}
                    <span
                      style={{
                        color: switchMic ? '#4CD964' : '#B2B2B2',
                        fontWeight: 'bold',
                      }}>
                      {switchMic ? 'ON' : 'OFF'}
                    </span>
                  </label>
                </div>
                <Switch
                  style={{ background: switchMic ? '#4CD964' : '#EAEDF0' }}
                  defaultChecked={switchMic}
                  onChange={switchMicrophone}
                />
              </div>
              <div className={styles.selectContent}>
                <div style={{ width: '100%' }}>
                  {defaultAudioInput !== '' ? (
                    <Select
                      placeholder="Selecciona Microfono"
                      defaultValue={defaultAudioInput}
                      style={{ width: '100%' }}
                      onChange={value => {
                        if (!switchVideo) switchCamera()
                        if (!switchMic) switchMicrophone()
                        setAudioInputSelect(value)
                      }}>
                      {audioInputArray && audioInputArray.map(device => {
                        return (
                          <Option key={device.deviceId} value={device.deviceId}>
                            {device.label}
                          </Option>
                        )
                      })}
                    </Select>
                  ) : (
                    Loading('small')
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default TestCamera
