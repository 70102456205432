import React from 'react'
// set if user have only one camera in call
const appointmentOnetSteps = [
  {
    selector: '[data-tut="appoint_1"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Estado de tu Doctor</h3>
          <div>
            Conoce si tu doctor se encuentra Conectado/Desconectado en la{' '}
            <span style={{ fontWeight: 'bold' }}>Consulta</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_2"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Chat</h3>
          <div>
            Utiliza este botón para enviar o recibir mensajes del{' '}
            <span style={{ fontWeight: 'bold' }}>Doctor</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_3"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Cámara</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>vea o no</span> tú cámara una
            vez esten <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_4"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Micrófono</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>escuche o no</span> tú
            micrófono una vez esten{' '}
            <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
]
// set if user  have two or more cameras in call
const appointmentTwotSteps = [
  {
    selector: '[data-tut="appoint_1"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Estado de tu Doctor</h3>
          <div>
            Conoce si tu doctor se encuentra Conectado/Desconectado en la{' '}
            <span style={{ fontWeight: 'bold' }}>Consulta</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_2"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Chat</h3>
          <div>
            Utiliza este botón para enviar o recibir mensajes del{' '}
            <span style={{ fontWeight: 'bold' }}>Doctor</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_3"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Cámara</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>vea o no</span> tú cámara una
            vez esten <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_4"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Micrófono</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>escuche o no</span> tú
            micrófono una vez esten{' '}
            <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appoint_5"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Cambio de Cámara</h3>
          <div>
            Puedes cambiar entre tus distintas cámaras conectadas, este cambio
            será visto por el <span style={{ fontWeight: 'bold' }}>Doctor</span>{' '}
            dentro de la <span style={{ fontWeight: 'bold' }}>Llamada</span>
          </div>
        </div>
      )
    },
  },
]
const appointmentMobileSteps = [
  {
    selector: '[data-tut="appointMob_1"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Estado de tu Doctor</h3>
          <div>
            Conoce si tu doctor se encuentra Conectado/Desconectado en la{' '}
            <span style={{ fontWeight: 'bold' }}>Consulta</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appointMob_2"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Chat</h3>
          <div>
            Utiliza este botón para enviar o recibir mensajes del{' '}
            <span style={{ fontWeight: 'bold' }}>Doctor</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appointMob_3"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Cambio de Cámara</h3>
          <div>
            Puedes cambiar entre tu cámara delantera/trasera, este cambio será
            visto por el <span style={{ fontWeight: 'bold' }}>Doctor</span>{' '}
            dentro de la <span style={{ fontWeight: 'bold' }}>Llamada</span>
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appointMob_4"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Cámara</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>vea o no</span> tú cámara una
            vez esten <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="appointMob_5"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Micrófono</h3>
          <div>
            Controla que el Doctor{' '}
            <span style={{ fontWeight: 'bold' }}>escuche o no</span> tú
            micrófono una vez esten{' '}
            <span style={{ fontWeight: 'bold' }}>conectados</span>.
          </div>
        </div>
      )
    },
  },
]

export { appointmentOnetSteps, appointmentTwotSteps, appointmentMobileSteps }
