import React from 'react'

import { Row, Col, Spin } from 'antd'

import InboxOutlined from '@ant-design/icons/InboxOutlined'

import styles from '../LobbyScreenStyles.module.css'

const FileDropFilesManagement = ({
  loadingUploadsList,
  uploadingFileService,
  processingFileService,
  filesLoaded,
}) => {
  return (
    <>
      {loadingUploadsList || processingFileService ? (
        <Row justify="center">
          <Col span={24} align="center">
            <InboxOutlined className={styles.filesDrawerLeftBoxIcon} />
          </Col>
          <Col span={24} align="center">
            <h3 className={styles.fileDropText}>Procesando archivo</h3>
          </Col>
          <Col span={24} align="center">
            <Spin />
          </Col>
        </Row>
      ) : (
        <div
          style={{
            backgroundColor: '#F2F2F2',
            margin: 40,
            border: 'solid',
            borderColor: 'black',
            borderWidth: 1,
            borderRadius: 40,
            cursor: 'pointer',
            padding: 40,
          }}>
          <Row justify="center">
            <Col span={24} align="center">
              <InboxOutlined className={styles.filesDrawerLeftBoxIcon} />
            </Col>
            <Col span={24} align="center">
              <h3 className={styles.fileDropText}>
                Arrastra o presiona aquí para cargar tus archivos
              </h3>
            </Col>
            <Col span={24} align="center">
              <span style={{ color: '#898989' }}>
                Sube tus archivos para la consulta, los archivos deben ser{' '}
                <b>jpg, jpeg, doc</b> y que no superen un peso máximo de{' '}
                <b>10MB</b> por archivo
              </span>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default FileDropFilesManagement
