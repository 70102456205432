import { useMutation } from '@apollo/react-hooks'
import { CONFIRM_APPOINTMENT } from 'App/Queries'

const UseConfirmAppointment = () => {
  const [confirmAppointment, { data, loading, error }] = useMutation(
    CONFIRM_APPOINTMENT,
  )

  return {
    confirmAppointment,
    data,
    loading,
    error,
  }
}

export default UseConfirmAppointment
