import ApolloClient from 'apollo-boost'

const Client =
  new ApolloClient({
    uri:
      process.env.REACT_APP_URL_ENDPOINT ||
      'https://b1ksxkywi8.execute-api.us-east-1.amazonaws.com/development/graphql',
  })


export { Client }
