import uniqid from 'uniqid'

const getUploadUrl = (file, inputFileRef, appointment, uploadUrl) => {

    const fileName = `${uniqid()}_${appointment.code}_${file.name}`

    uploadUrl({
        variables: { room: appointment.room, filename: fileName },
    })

}

export default getUploadUrl