import { useEffect, useState } from 'react'

const UsePatientAndDoctorConnected = (ablyState, professionalId) => {
  const [connected, setConnected] = useState(null)

  useEffect(() => {
    if (ablyState.appointmentRoomId) {
      const patientAndProfessionalConnected = ablyState.appointmentRoomId.find(
        obj => obj.clientId === professionalId,
      )

      setConnected(patientAndProfessionalConnected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ablyState.appointmentRoomId])

  return connected
}

export default UsePatientAndDoctorConnected
