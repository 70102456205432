import { useState, useEffect, useContext } from 'react'

import { AblyContext } from 'App/Providers'
import { GET_UPLOADS, UPLOAD_URL, DELETE_UPLOAD } from 'App/Queries'
import { useQuery, useLazyQuery } from 'react-apollo'
import Axios from 'axios'

import { notification } from 'antd'

import { getUploadUrl, uploadFile } from './Services/'

import { useInitFilesChannel } from './Helpers/'

const FilesManagementService = ({ appointment, inputFileRef }) => {
  const { realtime } = useContext(AblyContext)
  const channel = useInitFilesChannel(realtime, appointment)
  const [files, setFiles] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(false)
  const [uploadingFileService, setUploadingFileService] = useState(false)
  const [deletingFileService, setDeletingFileService] = useState(false)
  const [processingFileService, setProcessingFileService] = useState(false)

  const openNotification = placement => {
    notification.warning({
      message: `Advertencia!`,
      description: 'Alcanzaste el límite de 5 archivos',
      placement,
    })
  }

  const notAllowedType = placement => {
    notification.warning({
      message: `Advertencia!`,
      description: 'El tipo de archivo no está permitido',
      placement,
    })
  }

  // #region list files management
  const {
    data: dataUploadsList,
    loading: loadingUploadsList,
    error: errorUploadsList,
    refetch: refetchUploadsList,
  } = useQuery(GET_UPLOADS, {
    variables: { room: appointment.room },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  // #endregion list files management

  // #region delete files management
  const [
    getUrlForDelete,
    {
      data: dataGetUrlForDelete,
      loading: loadingGetUrlForDelete,
      error: errorGetUrlForDelete,
    },
  ] = useLazyQuery(DELETE_UPLOAD, {
    onCompleted: async data => {
      const { uploadDeleteUrl } = data
      setProcessingFileService(true)
      const response = await Axios.delete(uploadDeleteUrl)
      if (response.status === 204) {
        setDeletingFileService(false)
        setProcessingFileService(false)
        channel.publish('update', {})
        refetchUploadsList()
      }
    },
  })

  useEffect(() => {
    if (loadingGetUrlForDelete) {
      setDeletingFileService(true)
      setProcessingFileService(true)
    }
  }, [loadingGetUrlForDelete])
  // #endregion delete files management

  // #region uploads file management
  const [
    uploadUrl,
    {
      data: dataGetUploadUrl,
      loading: loadingGetUploadUrl,
      error: errorGetUploadUrl,
    },
  ] = useLazyQuery(UPLOAD_URL, {})

  const allowedTypes = [
    'application/pdf', // .pdf file
    'application/vnd.ms-excel', // .xls file
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx file
    'application/msword', // .doc file
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx file
  ]

  const initFileUpload = e => {
    if (dataUploadsList.uploads.length >= 5) openNotification('bottomLeft')
    else if (!allowedTypes.includes(e.target.files[0].type)) {
      notAllowedType('bottomLeft')
      inputFileRef.current.value = ''
    } else {
      setFiles(e.target.files[0])

      setUploadingFileService(true)
      setProcessingFileService(true)

      getUploadUrl(e.target.files[0], inputFileRef, appointment, uploadUrl)
      inputFileRef.current.value = ''
    }
  }

  const initFileUploadOnDrop = files => {
    if (dataUploadsList.uploads.length >= 5) openNotification('bottomLeft')
    else {
      setFiles(files[0])

      setUploadingFileService(true)
      setProcessingFileService(true)

      getUploadUrl(files[0], inputFileRef, appointment, uploadUrl)
      inputFileRef.current.value = ''
    }
  }

  useEffect(() => {
    if (dataGetUploadUrl) {
      setUploadPercent(0)
      uploadFile(
        channel,
        dataGetUploadUrl,
        loadingGetUploadUrl,
        errorGetUploadUrl,
        setUploadingFileService,
        setProcessingFileService,
        setUploadPercent,
        refetchUploadsList,
        files,
      )
    }
  }, [
    channel,
    dataGetUploadUrl,
    errorGetUploadUrl,
    files,
    loadingGetUploadUrl,
    refetchUploadsList,
  ])
  // #endregion uploads file management

  useEffect(() => {}, [loadingUploadsList, processingFileService])

  return {
    dataUploadsList,
    loadingUploadsList,
    errorUploadsList,
    refetchUploadsList,

    initFileUpload,
    initFileUploadOnDrop,
    uploadingFileService,
    processingFileService,
    uploadPercent,
    setUploadPercent,

    getUrlForDelete,
    dataGetUrlForDelete,
    loadingGetUrlForDelete,
    errorGetUrlForDelete,
    deletingFileService,
  }
}

export default FilesManagementService
