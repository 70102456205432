function SoundMeter(context) {
  this.context = context
  this.instant = 0.0

  this.script = context.createScriptProcessor(2048, 1, 1)
  const that = this
  this.script.onaudioprocess = function(event) {
    const input = event.inputBuffer.getChannelData(0)
    let i
    let sum = 0.0
    for (i = 0; i < input.length; ++i) sum += input[i] * input[i]
    that.instant = Math.sqrt(sum / input.length)
  }
}

SoundMeter.prototype.connectToSource = function(stream, callback) {
  // console.log('SoundMeter connecting')
  try {
    this.mic = this.context.createMediaStreamSource(stream)
    this.mic.connect(this.script)
    // console.log('SoundMeter conected')
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination)
    if (typeof callback !== 'undefined') callback(null)
  } catch (e) {
    console.error(e)
    if (typeof callback !== 'undefined') callback(e)
  }
}

SoundMeter.prototype.stop = function() {
  this.mic.disconnect()
  this.script.disconnect()
}

export { SoundMeter }
