import { gql } from 'apollo-boost'

const GET_UPLOADS = gql`
  query($room: String!) {
    uploads(room: $room) {
      size
      key
    }
  }
`

const GET_UPLOAD_FETCH_URL = gql`
  query($key: String!) {
    uploadFetchUrl(key: $key)
  }
`

const DELETE_UPLOAD = gql`
  query($key: String!) {
    uploadDeleteUrl(
      key: $key
    )
  }
`

const UPLOAD_URL = gql`
  query($room: String! $filename: String!){
    uploadUrl(
      room: $room
      filename: $filename
    )
  }
`

export { GET_UPLOADS, GET_UPLOAD_FETCH_URL, DELETE_UPLOAD, UPLOAD_URL }
