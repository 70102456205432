import React, { useState, useRef, useEffect } from 'react'
import styles from '../BrowserCompatibility.module.css'
import { Button } from 'antd'

const Clipboard = ({ browserObj }) => {
  const [windowLocationPath, setWindowLocationPath] = useState('')
  const clipBoard = useRef(null)
  const copyClipboard = () => {
    if (browserObj.so === 'iOS')
      prompt(`Copia el siguiente Enlace `, windowLocationPath)
    else {
      clipBoard.current.select()
      document.execCommand('copy')
    }
  }
  useEffect(() => {
    setWindowLocationPath(window.location.href)
  }, [windowLocationPath])
  return (
    <div className={styles.recomendatioBoxBrowser}>
      <div className={styles.recomendationBrowser}>
        <h3 style={{ color: '#2D3F8C', fontWeight: 'bold' }}>
          Copia el siguiente Enlace y Pegalo en el navegador recomendado
        </h3>
      </div>
      <div className={styles.browserCopyPath}>
        <div className={styles.browserCopyPathData}>
          <div className={styles.copyPath}>
            <input ref={clipBoard} value={windowLocationPath} />
          </div>
          <div className={styles.copyClipboard}>
            <Button type="default" onClick={copyClipboard}>
              Copiar Enlace
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Clipboard
