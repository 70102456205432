import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Button, Row, Col, Alert, notification } from 'antd'
import { AblyContext } from '../../../Providers'
import dayjs from 'dayjs'

import Warning from 'App/Assets/Images/Warning.png'
import NextIcon from 'App/Assets/SVG/NextIcon'
import {
  WaitingStatusIcon,
  ConnectedStatusIcon,
  DisconnectedStatusIcon,
  InOtherAppointmentStatusIcon,
  FinishedStatusIcon,
} from 'App/Assets/SVG/StatusIcons'

import styles from '../LobbyScreenStyles.module.css'

const ConnectionComponent = ({ icon, label, color }) => {
  return (
    <>
      <div
        className={styles.connectionStatusContainer}
        style={{ background: color }}>
        {icon}
      </div>
      <p className={styles.cardTextConnect}>{label}</p>
    </>
  )
}

const AppointmentInfo = ({
  drawerOnCloseHandler,
  appointment,
  startMeeting,
  onlineUsers,
  roomUsers,
  canAccess,
  realtime,
  errorOfCameraAndAudio,
  setModalError,
  canEntryToAppointment,
  isTourOpenLobby,
  channelListener,
  isFinishedAppointment,
  setDoctorIsInAppointmentOrLobby,
  doctorIsInAppointmentOrLobby,
}) => {
  const location = useLocation()
  const history = useHistory()
  const { token } = useParams()
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [isClosedAppointment, setIsClosedAppointment] = useState(
    isFinishedAppointment,
  )

  const [allowed, setAllowed] = useState(false)
  const isEarly = false
  const ablyState = useContext(AblyContext)
  const { stateConnection } = ablyState

  const ListenerDoctorWasInAppointment = useCallback(channelListener => {
    channelListener.subscribe(`inAppointmentMessage`, message => {
      const { inAppointment } = message.data
      setDoctorIsInAppointmentOrLobby(inAppointment)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // call channel lister if doctor send message by ably
  useEffect(() => {
    if (channelListener) ListenerDoctorWasInAppointment(channelListener)
  }, [ListenerDoctorWasInAppointment, channelListener])

  // if doctor send message then change connectionStatus
  useEffect(() => {
    if (doctorIsInAppointmentOrLobby !== null && connectionStatus !== 'done')
      if (doctorIsInAppointmentOrLobby) {
        channelListener.unsubscribe()
        const isInRoom = roomUsers.find(
          p => p.clientId === appointment.professional._id,
        )
        if (isInRoom) setConnectionStatus('inProgress')
        else {
          const isOnline = onlineUsers.find(
            p => p.clientId === appointment.professional._id,
          )
          const connectionStatus = isOnline
            ? 'inAnotherAppointment'
            : 'disconnected'
          setConnectionStatus(connectionStatus)
        }
      } else {
        const isInRoom = roomUsers.find(
          p => p.clientId === appointment.professional._id,
        )
        if (isInRoom) setConnectionStatus('inProgress')
        else {
          const isOnline = onlineUsers.find(
            p => p.clientId === appointment.professional._id,
          )
          const connectionStatus = isOnline ? 'inLobby' : 'disconnected'
          setConnectionStatus(connectionStatus)
        }
      }
  }, [
    appointment.professional._id,
    connectionStatus,
    doctorIsInAppointmentOrLobby,
    onlineUsers,
    roomUsers,
    channelListener,
  ])

  useEffect(() => {
    if (appointment.status === 'CLOSED' || isFinishedAppointment)
      setIsClosedAppointment(true)
  }, [appointment.status, isFinishedAppointment])

  useEffect(() => {
    if (
      roomUsers.length > 0 &&
      onlineUsers.length > 0 &&
      !channelListener &&
      !realtime
    )
      return

    if (location.state !== undefined)
      setConnectionStatus(location.state.connectionStatus)
    else if (appointment.status === 'CLOSED') setConnectionStatus('done')
    else {
      const isInRoom = roomUsers.find(
        p => p.clientId === appointment.professional._id,
      )
      if (isInRoom) setConnectionStatus('inProgress')
      else {
        const isOnline = onlineUsers.find(
          p => p.clientId === appointment.professional._id,
        )
        const connectionStatus = isOnline ? 'connected' : 'disconnected'
        setConnectionStatus(connectionStatus)
      }
    }
  }, [
    appointment.professional._id,
    appointment.status,
    channelListener,
    location.state,
    onlineUsers,
    realtime,
    roomUsers,
  ])

  useEffect(() => {
    if (
      (connectionStatus === 'inProgress' || connectionStatus === 'inLobby') &&
      canAccess !== null &&
      canAccess
    )
      setAllowed(true)
    else {
      setAllowed(false)
      if (
        (connectionStatus === 'connected' || connectionStatus === 'inLobby') &&
        !canAccess
      )
        setAllowed(true)
    }
  }, [canAccess, connectionStatus])

  const time = dayjs(appointment.scheduledAt).format('HH:mm')

  const redirectOrStart = () => {
    if (isClosedAppointment) setModalError(true)
    if (!errorOfCameraAndAudio)
      if (canEntryToAppointment) {
        if (!localStorage.getItem('tour')) {
          const objTour = {
            lobby: true,
            room: false,
            day: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          }
          localStorage.setItem('tour', JSON.stringify(objTour))
        }

        if (connectionStatus === 'inProgress')
          return history.push(`/appointment/${token}`)

        startMeeting()
      } else {
        const args = {
          message: 'No encontramos Video ni Audio',
          description: 'Da los permisos necesarios para comenzar la llamada',
          duration: 5,
        }
        notification.open(args)
      }
    else setModalError(true)
  }

  return (
    <>
      <div className={styles.card}>
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
            height: '25%',
            width: '100%',
          }}>
          <h5
            style={{
              color: '#6B7CFF',
              marginBottom: '0px',
            }}>
            Bienvenido{' '}
            <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              {appointment.patient.firstname} {appointment.patient.lastname}
            </span>
          </h5>
        </div>

        <div className={styles.cardHeader}>
          <div className={styles.cardPatientData} data-tut='lobby_1'>
            <p className={styles.cardText} style={{ marginBottom: 0 }}>
              Tu consulta de{' '}
              <span style={{ fontWeight: 'bold' }}>{`${time}hrs.`}</span> con{' '}
              <span style={{ fontWeight: 'bold' }}>
                {appointment.professional.firstname}{' '}
                {appointment.professional.lastname}
              </span>
            </p>
            {(appointment.status === 'CLOSED') | isClosedAppointment ? (
              <div className={styles.closedAppointment}>
                <ConnectionComponent
                  label='Consulta finalizada'
                  color='#6B7CFF'
                  icon={
                    <FinishedStatusIcon
                      width='8px'
                      height='10px'
                      stroke='white'
                    />
                  }
                />
              </div>
            ) : (
              <div className={styles.patientNetworkStatus}>
                {stateConnection === 'connected' ? (
                  connectionStatus === 'disconnected' ? (
                    <ConnectionComponent
                      label='Doctor está desconectado'
                      color='#979797'
                      icon={
                        <DisconnectedStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  ) : connectionStatus === 'inProgress' ? (
                    <ConnectionComponent
                      label='Doctor está esperandote'
                      color='#5CC430'
                      icon={
                        <WaitingStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  ) : connectionStatus === 'inAnotherAppointment' ? (
                    <ConnectionComponent
                      label='Doctor está en otra Consulta'
                      color='#ff768e'
                      icon={
                        <InOtherAppointmentStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  ) : connectionStatus === 'inLobby' ? (
                    <ConnectionComponent
                      label='Doctor está conectado'
                      color='#5CC531'
                      icon={
                        <ConnectedStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  ) : connectionStatus === 'connected' ? (
                    <ConnectionComponent
                      label='Doctor está conectado'
                      color='#5CC531'
                      icon={
                        <ConnectedStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  ) : (
                    <ConnectionComponent
                      label='Consulta finalizada'
                      color='#6B7CFF'
                      icon={
                        <FinishedStatusIcon
                          width='8px'
                          height='10px'
                          stroke='white'
                        />
                      }
                    />
                  )
                ) : (
                  <ConnectionComponent
                    label='Doctor está desconectado'
                    color='#979797'
                    icon={
                      <DisconnectedStatusIcon
                        width='8px'
                        height='10px'
                        stroke='white'
                      />
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div className={styles.cardPatientData} style={{ padding: '0px' }}>
            <p className={styles.cardText} style={{ color: '#959595' }}>
              Recuerda que puedes{' '}
              <span
                style={{
                  color: '#6B7CFF',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!isTourOpenLobby)
                    if (connectionStatus !== 'done') drawerOnCloseHandler()
                }}>
                Adjuntar tus archivos
              </span>{' '}
              antes o durante la consulta con{' '}
              <span style={{ color: '#959595', fontWeight: 'bold' }}>
                el botón
              </span>
            </p>
          </div>
        </div>

        {appointment.status === 'CLOSED' ? (
          <>
            <div className={styles.cardBodyExpired}>
              <Alert
                message='Estimado cliente'
                description={
                  <p>
                    La consulta ya ha finalizado, su hora fue a las{' '}
                    <span
                      style={{
                        color: '#6B7CFF',
                        fontWeight: 'bold',
                      }}>{`${time}hrs.`}</span>
                  </p>
                }
                type='info'
                showIcon
                closable
              />
            </div>
          </>
        ) : appointment.status === 'EXPIRED' ? (
          <>
            <div className={styles.cardBodyExpired}>
              <Alert
                message='Lo sentimos'
                description={
                  <p>
                    La consulta ya pasó, su hora fue a las{' '}
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                      }}>{`${time}hrs.`}</span>
                  </p>
                }
                type='error'
                showIcon
                closable
              />
            </div>
          </>
        ) : !isEarly ? (
          <Row className={styles.AppointmentsButtons}>
            <Col
              lg={18}
              md={18}
              sm={18}
              xs={18}
              data-tut='lobby_6'
              className={styles.buttonsCol}>
              <Button
                disabled={!allowed || isClosedAppointment}
                type='ghost'
                shape='round'
                size={'large'}
                className={styles.startMeeting}
                onClick={redirectOrStart}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                  }}>
                  <div style={{ width: '90%' }}>Ingresar a consulta</div>
                  <div
                    style={{
                      position: 'absolute',
                      right: '0px',
                      top: '-14px',
                    }}>
                    <NextIcon width='27px' height='27px' fill='white' />
                  </div>
                </div>
              </Button>
            </Col>
          </Row>
        ) : (
          <>
            <div className={styles.cardBodyEarly}>
              <div className={styles.rowNoSpaceExpired}>
                <img src={Warning} alt='warning' className={styles.icon} />
                <div style={{ marginLeft: '3%' }}>
                  <p className={styles.cardText} style={{ lineHeight: '2vh' }}>
                    Estimado paciente has llegado antes de tiempo a tu cita.
                  </p>
                  <p className={styles.cardText} style={{ paddingTop: 10 }}>
                    Hora de consulta:
                  </p>
                  <p
                    className={styles.cardText}
                    style={{ fontWeight: 'bold' }}>{`${time}hrs.`}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default AppointmentInfo
