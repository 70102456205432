import React, { useCallback, useState, useEffect, useContext } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'
import get from 'lodash/get'
import Tour from 'reactour'
import { Row, Col, Spin, Modal } from 'antd'
import dayjs from 'dayjs'
import localization from 'dayjs/locale/es'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

// import { usePatientArrived } from './Services'
// import { UseChannelsService, ManageReactorExecution } from 'App/Services'
import { UseChannelsService } from 'App/Services'

import { Animated } from 'react-animated-css'
import LobbyHeader from './Components/LobbyHeader'
import AppointmentInfo from './Components/AppointmentInfo'
import AppointmentNotFound from './Components/AppointmentNotFound'
import WaitingRoom from './Components/WaitingRoom'
import TestCamera from './Components/TestCamera'
import FilesDrawer from './Components/FilesDrawer'
import ErrorTestCamera from './Components/ErrorTestCamera'
import enterPresenceChannel from 'App/Helpers/enterPresenceChannel'
import leavePresenceChannel from 'App/Helpers/leavePresenceChannel'
import receivedSecretaryMessage from 'App/Screens/receivedSecretaryMessage'
import { GET_APPOINTMENTS } from 'App/Queries'
import { AblyContext } from 'App/Providers'
import { lobbySteps, EndButton } from 'App/Helpers/Tour'
import getWindowDimensions from 'App/Helpers/windowResize'

import styles from './LobbyScreenStyles.module.css'

const LobbyScreen = ({ history }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )
  const [modalError, setModalError] = useState(false)
  const [errorMssge, setErrorMssge] = useState('')
  const [canBeOpenTour, setCanBeOpenTour] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [tourInFileDrawerIsOpen, setTourInFileDrawerIsOpen] = useState(true)
  const [userDoItTour, setUserDoItTour] = useState(false)
  const [notTourInError, setNotTourInError] = useState(false)
  const [showWaitingRoom, setShowWaitingRoom] = useState(false)
  const [realtime, setRealtime] = useState(null)
  const [onlineUsers, setOnlineUsers] = useState([])
  const [roomUsers, setRoomUsers] = useState([])
  const { token } = useParams()
  const [canAccess, setCanAccess] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [errorOfCameraAndAudio, setErrorOfCameraAndAudio] = useState(false)
  const [canEntryToAppointment, setCanEntryToAppointment] = useState(false)
  const [channelListener, setChannelListener] = useState(null)
  const [enterToWaitRoom, setEnterToWaitRoom] = useState(null)
  const [goToDrawer, setGoToDrawer] = useState(null)
  const [isClosedAppointment, setIsClosedAppointment] = useState(false)
  const startMeeting = () => setShowWaitingRoom(true)
  const [statusDoctor, setStatusDoctor] = useStateIfMounted(false)
  const [
    doctorIsInAppointmentOrLobby,
    setDoctorIsInAppointmentOrLobby,
  ] = useStateIfMounted(null)

  const drawerOnCloseHandler = () => {
    // if (isTourOpenLobby) setIsTourOpenLobby(false)
    setDrawerVisible(!drawerVisible)
  }

  const { error, data, loading } = useQuery(GET_APPOINTMENTS, {
    variables: { token },
  })
  if (error) console.log('my error', error)

  const appointment = get(data, 'appointment')
  const professionalId = get(data, 'appointment.professional._id')
  const appointmentRoom = get(data, 'appointment.room')
  const appointmentStatus = get(data, 'appointment.status')

  const ablyState = useContext(AblyContext)

  UseChannelsService(ablyState, professionalId, appointmentRoom, appointment)
  // ManageReactorExecution(appointment)

  const realtimeAbbly = ablyState.realtime

  useEffect(() => {
    if (appointmentStatus && appointmentStatus === 'CLOSED')
      setIsClosedAppointment(true)
  }, [appointmentStatus])

  useEffect(() => {
    if (realtimeAbbly !== null || realtime === null)
      setRealtime(ablyState.realtime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeAbbly])

  useEffect(() => {
    receivedSecretaryMessage(appointment, realtimeAbbly)
  }, [appointment, realtimeAbbly])

  // `queue_reactor_patient_${patientId}_${appointmentId}_${room}`
  const cleanUpAbly = useCallback(async () => {
    if (!realtime || !professionalId) return

    leavePresenceChannel(realtime, `wait-room:${professionalId}`)
    leavePresenceChannel(realtime, 'wait-room')
    leavePresenceChannel(realtime, `appointment:${appointmentRoom}`)
    // const { _id: patientId } = appointment.patient
    // const { _id: appointmentId, room } = appointment
    // leavePresenceChannel(
    //   realtime,
    //   `queue_reactor_patient_${patientId}_${appointmentId}_${room}`,
    // )
    /* leavePresenceChannel(realtime, '5f2c43856a66a70007963b29')
    leavePresenceChannel(
      realtime,
      'queue_reactor_patient_5f2c43856a66a70007963b29_5f809c9cd46ed500071fccb2_MjIxNjA5NzQtODM5Yy00MWYxLWE4MWYtMDY3NzE1NDRjZmVk',
    )
    leavePresenceChannel(
      realtime,
      'appointment:MjIxNjA5NzQtODM5Yy00MWYxLWE4MWYtMDY3NzE1NDRjZmVk',
    ) */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime])

  useEffect(() => {
    const subToRoom = async () => {
      if (!appointmentRoom || !realtime) return
      const channel = await realtime.channels.get(
        `appointment:${appointmentRoom}`,
      )
      channel.presence.get((err, presenceSet) => {
        if (err) return console.error(err)
        setRoomUsers(presenceSet)
        channel.presence.subscribe(() => {
          channel.presence.get((err, presenceSet) => {
            if (err) return console.error(err)
            setRoomUsers(presenceSet)
          })
        })
      })
    }
    subToRoom()
  }, [appointment, appointmentRoom, realtime])

  useEffect(() => {
    if (realtime) {
      // leaveAllChannels(realtime)
      const isAppointmentFinished = async () => {
        const channelName = `appointment:${appointmentRoom}`
        const channel = await realtime.channels.get(channelName)
        channel.attach(err => {
          if (err) console.error(err)
          channel.subscribe('finished', data => {
            setIsClosedAppointment(true)
            cleanUpAbly()
          })
        })
      }
      isAppointmentFinished()
    }
  }, [appointment, appointmentRoom, realtime, cleanUpAbly])

  useEffect(() => {
    if (!realtime || !appointment) return

    setEnterToWaitRoom(true)
    enterPresenceChannel(
      realtime,
      `wait-room:${professionalId}`,
      setOnlineUsers,
    )
    enterPresenceChannel(realtime, 'wait-room')
    return () => {
      if (realtime && appointment) cleanUpAbly()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, cleanUpAbly, professionalId, realtime])

  useEffect(() => {
    const updateDoctorStatus = () => {
      if (!isClosedAppointment)
        setTimeout(() => {
          setStatusDoctor(!statusDoctor)
        }, 700)
    }
    updateDoctorStatus()

    return () => clearTimeout(updateDoctorStatus)
  })

  useEffect(() => {
    const addChannelListenerForDoctorStatus = async () => {
      if (realtime && enterToWaitRoom && professionalId) {
        const channel = await realtime.channels.get(
          `wait-room:${professionalId}`,
        )
        channel.subscribe(`inAppointmentMessage`, message => {
          const { inAppointment } = message.data
          setDoctorIsInAppointmentOrLobby(inAppointment)
        })
        setChannelListener(channel)
      }
    }
    addChannelListenerForDoctorStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterToWaitRoom, professionalId, realtime, statusDoctor])

  useEffect(() => {
    if (!tourInFileDrawerIsOpen && goToDrawer !== null) setIsTourOpen(true)
  }, [goToDrawer, tourInFileDrawerIsOpen])

  useEffect(() => {
    const isIPadPro =
      /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document
    if (isIPadPro) setIsMobile(true)
    else setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))

    const handleResize = () => setWindowDimensions(getWindowDimensions())
    window.addEventListener('resize', handleResize)

    if (localStorage.getItem('tour')) {
      const objTour = JSON.parse(localStorage.getItem('tour'))
      setUserDoItTour(objTour.lobby)
    } else
      setTimeout(() => {
        setIsTourOpen(true)
      }, 800)

    if (localStorage.getItem('refresh')) localStorage.removeItem('refresh')

    setTimeout(() => {
      const elements = document.querySelectorAll('#reactour__dot button')
      elements.forEach(el => (el.tabIndex = -1))
    }, 100)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (showWaitingRoom && realtime && appointment)
    return (
      <WaitingRoom
        appointment={appointment}
        token={token}
        realtime={realtime}
        history={history}
      />
    )

  if (loading)
    return (
      <Row
        gutter={24}
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '85vh' }}>
        <Col span={12} style={{ margin: 0 }}>
          {' '}
          <Spin size={'large'} style={{ marginLeft: '47%' }} />
        </Col>
      </Row>
    )

  if (!appointment)
    return <AppointmentNotFound windowDimensions={windowDimensions} />

  if (appointment) {
    const formattedCode = `${appointment.code.slice(
      0,
      4,
    )} ${appointment.code.substring(4)}`
    return (
      <Animated animationIn='bounceInLeft' animationInDuration={500}>
        <div className={styles.container}>
          {isTourOpen &&
            !notTourInError &&
            canBeOpenTour &&
            !isClosedAppointment && (
              <Tour
                onRequestClose={() => {
                  setIsTourOpen(false)
                  document.body.style.overflowY = 'auto'
                }}
                closeWithMask={false}
                goToStep={goToDrawer}
                steps={lobbySteps}
                isOpen={isTourOpen}
                maskClassName={styles.maskTour}
                className='helper'
                rounded={5}
                accentColor={'#6B7CFF'}
                lastStepNextButton={<EndButton>Intentalo tú</EndButton>}
                onAfterOpen={target =>
                  (document.body.style.overflowY = 'hidden')
                }
              />
            )}
          <LobbyHeader windowDimensions={windowDimensions} />

          <Row gutter={24} type='flex' justify='center' align='middle'>
            <div className={styles.col}>
              <div className={styles.rowHeader}>
                <div>
                  <h1 className={styles.title}>
                    {isClosedAppointment
                      ? 'Consulta Finalizada'
                      : 'Sala de espera'}
                  </h1>
                  <p className={styles.subtitle}>
                    Hoy{' '}
                    {dayjs()
                      .locale('es', localization)
                      .format(`dddd`)}
                    ,
                    <span style={{ color: '#6B7CFF' }}>
                      {dayjs()
                        .locale('es', localization)
                        .format(` D [de] MMMM`)}
                    </span>
                  </p>
                </div>
                <div className={styles.rowNoSpace}>
                  <p className={styles.text}>Código de consulta</p>
                  <div className={styles.codeContainer}>
                    <p>{formattedCode}</p>
                  </div>
                </div>
              </div>

              <div className={styles.videoAndInfoContainer}>
                <>
                  <div
                    className={styles.videoContainer}
                    disabled={isClosedAppointment}
                    hidden={isClosedAppointment}>
                    <TestCamera
                      isTourOpenTest={isTourOpen}
                      setCanAccess={setCanAccess}
                      isMobile={isMobile}
                      drawerOnCloseHandler={drawerOnCloseHandler}
                      drawerVisible={drawerVisible}
                      setNotTour={setNotTourInError}
                      setCanBeOpenTour={setCanBeOpenTour}
                      setErrorOfCameraAndAudio={setErrorOfCameraAndAudio}
                      setErrorMssge={setErrorMssge}
                      setModalError={setModalError}
                      setCanEntryToAppointment={setCanEntryToAppointment}
                      setGoToDrawer={setGoToDrawer}
                      setIsTourOpen={setIsTourOpen}
                    />
                  </div>
                </>

                <div className={styles.infoContainer}>
                  <AppointmentInfo
                    appointment={appointment}
                    windowDimensions={windowDimensions}
                    drawerOnCloseHandler={drawerOnCloseHandler}
                    startMeeting={startMeeting}
                    onlineUsers={onlineUsers}
                    roomUsers={roomUsers}
                    canAccess={canAccess}
                    realtime={realtime}
                    isTourOpenLobby={isTourOpen}
                    setIsTourOpenLobby={setIsTourOpen}
                    userDoItTour={userDoItTour}
                    errorOfCameraAndAudio={errorOfCameraAndAudio}
                    setModalError={setModalError}
                    canEntryToAppointment={canEntryToAppointment}
                    channelListener={channelListener}
                    isFinishedAppointment={isClosedAppointment}
                    doctorIsInAppointmentOrLobby={doctorIsInAppointmentOrLobby}
                    setDoctorIsInAppointmentOrLobby={
                      setDoctorIsInAppointmentOrLobby
                    }
                  />
                  <FilesDrawer
                    appointment={appointment}
                    drawerOnCloseHandler={drawerOnCloseHandler}
                    drawerVisible={drawerVisible}
                    realtime={realtime}
                    userDoItTour={userDoItTour}
                    setTourInFileDrawerIsOpen={setTourInFileDrawerIsOpen}
                  />
                </div>
              </div>
            </div>
          </Row>
          {!isClosedAppointment && (
            <Modal
              title='Tú cámara y/o micrófono están bloqueados.'
              visible={modalError}
              onCancel={() => {
                setModalError(false)
              }}
              footer={null}>
              <ErrorTestCamera errorMssge={errorMssge} isMobile={isMobile} />
            </Modal>
          )}
        </div>
      </Animated>
    )
  }
}
export default LobbyScreen
