import * as React from 'react'

function DisconnectedStatusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.412 6.95"
      width={props.width}
      height={props.height}
      stroke={props.stroke}
      fill={props.fill}
      strokeLinecap="round"
      {...props}>
      <g transform="translate(-28.794 -461.794)">
        <line
          className="a"
          x1="5"
          y1="5.538"
          transform="translate(29.5 462.5)"
        />
        <line
          className="a"
          y1="5.538"
          x2="5"
          transform="translate(29.5 462.5)"
        />
      </g>
    </svg>
  )
}

export default DisconnectedStatusIcon
