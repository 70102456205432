import Axios from 'axios'

import { notification } from 'antd'

const openNotification = placement => {
  notification.warning({
    message: `Ups!`,
    description: 'A ocurrido un error al subir el archivo, reintente',
    placement,
  })
}

const uploadFile = async (
  channel,
  data,
  loading,
  error,
  setUploadingFileService,
  setProcessingFileService,
  setUploadPercent,
  refetchUploadsList,
  files,
) => {
  if (data) {
    const options = {
      headers: {
        'Content-Type': files.type,
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        const roundedPercent = Math.round(percentCompleted / 10) * 10
        setUploadPercent(roundedPercent)
        if (roundedPercent === 100) setUploadPercent(100)
      },
    }
    const { uploadUrl } = data
    const response = await Axios.put(uploadUrl, files, options)
    if (response.status === 200) {
      channel.publish('update', {})
      refetchUploadsList()
    }
    setUploadingFileService(false)
    setProcessingFileService(false)
  }
  if (loading) console.log('Obteniendo URL')
  if (error) openNotification('bottomLeft')
}

export default uploadFile
