import styled from 'styled-components'

const EndButton = styled.div`
  padding: 10px 20px;
  background: #6a7bff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
`

export { EndButton }
