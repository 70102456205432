import React, { useState } from 'react'
import Tour from 'reactour'
import { Drawer } from 'antd'

import { lobbyDrawerSteps, EndButton } from 'App/Helpers/Tour'
import { DrawerFilesManagement } from 'App/GlobalComponents/'

import styles from '../LobbyScreenStyles.module.css'

const FilesDrawer = ({ appointment, drawerOnCloseHandler, drawerVisible }) => {
  // Tour hooks
  const [isTourOpenDrawer, setIsTourOpenDrawer] = useState(false)

  return (
    <>
      {isTourOpenDrawer && (
        <Tour
          onRequestClose={() => {
            setIsTourOpenDrawer(false)
            document.body.style.overflowY = 'auto'
          }}
          closeWithMask={false}
          steps={lobbyDrawerSteps}
          isOpen={isTourOpenDrawer}
          maskClassName={styles.maskTour}
          className="helper"
          rounded={5}
          accentColor={'#6B7CFF'}
          lastStepNextButton={<EndButton>Intentalo tú</EndButton>}
          onAfterOpen={target => (document.body.style.overflowY = 'hidden')}
        />
      )}

      <Drawer
        placement={'bottom'}
        closable
        onClose={drawerOnCloseHandler}
        visible={drawerVisible}
        height={'50%'}
        key={2}>

        <DrawerFilesManagement appointment={appointment} />

      </Drawer>
    </>
  )
}

export default FilesDrawer
