import React from 'react'
import { Redirect } from 'react-router-dom'
import { Col, Row, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import is from 'is_js'

const { Text, Title } = Typography

const UnsupportedBrowserScreen = () => {
  if (!is.ie()) return <Redirect to="/" />

  return (
    <Row>
      <Col md={24} style={{ textAlign: 'center', marginTop: 200 }}>
        <ExclamationCircleOutlined
          style={{ fontSize: '124px', color: '#08c' }}
        />
      </Col>
      <Col style={{ textAlign: 'center', marginTop: 50 }}>
        <Title>Navegador no soportado</Title>
        <Text>
          Admitimos las versiones actuales de Chrome, Firefox, Safari y
          Microsoft Edge.
        </Text>
      </Col>
    </Row>
  )
}

export default UnsupportedBrowserScreen
