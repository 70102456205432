import React from 'react'
import { Layout, Breadcrumb, Row, Col } from 'antd'
import { withRouter, Redirect } from 'react-router-dom'
import is from 'is_js'
import useIsMobile from '../../Helpers/useIsMobile'

const AppLayout = ({ history, children }) => {
  const isMobile = useIsMobile()

  if (is.ie()) return <Redirect to="/unsupported-browser" />
  if (isMobile)
    return (
      <Layout style={{ height: '100vh', background: 'white' }}>
        {children}
      </Layout>
    )

  return (
    <Layout
      style={{
        height: '100vh',
      }}>
      <Row style={{ height: '100vh' }} justify="space-around" align="middle">
        <Col md={24}>
          <Row style={{}} justify="space-around" align="middle">
            <Col
              md={24}
              style={{
                height: '100vh',
                maxHeight: '100vh',
                backgroundColor: 'white',
              }}>
              <Breadcrumb></Breadcrumb>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default withRouter(AppLayout)
