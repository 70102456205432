import React, { useEffect, useCallback } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'
import { SoundMeter } from './soundmeter'
import styles from './Index.module.css'


const SoundMic = ({ active, LocalStream }) => {
  const [instantValueDisplay, setInstantValueDisplay] = useStateIfMounted(0)
  const [soundMeter, setSoundMeter] = useStateIfMounted(null)

  try {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    window.audioContext = new AudioContext()
  } catch (e) {
    alert('Web Audio API not supported.')
  }

  // Put variables in global scope to make them available to the browser console.

  const beginSoundMeter = useCallback(() => {
    const soundMeter = (window.soundMeter = new SoundMeter(window.audioContext))
    setSoundMeter(soundMeter)
    soundMeter.connectToSource(LocalStream, e => {
      if (e) {
        alert(e)
        return
      }
      setInterval(() => {
        // instantMeter.current.value = soundMeter.instant.toFixed(2)
        soundMeter && soundMeter.instant &&
        setInstantValueDisplay(soundMeter.instant.toFixed(2))
      }, 100)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalStream])

  useEffect(() => {
    if (soundMeter === null && active && LocalStream !== '') beginSoundMeter()
  }, [LocalStream, active, beginSoundMeter, soundMeter])

  return (
    <div className={styles.soundMeter}>
      <div
        className={instantValueDisplay > 0 ? styles.circleMeterPulse : {}}
        style={{
          width: '23px',
          height: '23px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}>
        <div className={styles.circleMeter} />
      </div>
    </div>
  )
}

export default SoundMic
