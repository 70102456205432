import React from 'react'
import styles from '../BrowserCompatibility.module.css'
import firefoxImg from '../../../Assets/Images/firefox.png'
import chromeImg from '../../../Assets/Images/chrome.png'
import safariImg from '../../../Assets/Images/safari.png'
import DownloadIcon from '../../../Assets/SVG/DownloadIcon'
import { Tag } from 'antd'

const ImgBrowserInfo = ({ isDesktop, browserObj }) => {
  const urlChrome =
    'https://www.google.com.mx/chrome/?brand=CHBD&gclid=EAIaIQobChMIyLWDtoqd6QIVEwSRCh28nQV9EAAYASAAEgL-APD_BwE&gclsrc=aw.ds'
  const urlFirefox = 'https://www.mozilla.org/es-CL/firefox/new/'
  const urlSafari = 'https://support.apple.com/downloads/safari'
  return (
    <div className={styles.imgBrowserContent}>
      {isDesktop ? (
        <div className={styles.downloadTextBrowser}>
          <h4 style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
            {' '}
            Descarga un navegador:
          </h4>
        </div>
      ) : browserObj.so === 'iOS' ? null : (
        <div className={styles.downloadTextBrowser}>
          <h4 style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
            {' '}
            Descarga el navegador:
          </h4>
        </div>
      )}
      {isDesktop ? (
        <>
          {browserObj.so === 'Windows' || browserObj.so === 'Linux' ? (
            <div className={styles.imgBrowser}>
              <div className={styles.imgBrowserTwo}>
                <div className={styles.imgCardBrowser}>
                  <img
                    alt="example"
                    width="50%"
                    height="71%"
                    src={firefoxImg}
                  />
                  <Tag style={{ marginTop: '8px', width: '100px' }}>
                    <a
                      href={urlFirefox}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                      <div className={styles.imgDownloadContainer}>
                        <DownloadIcon width="12px" />
                        Firefox
                      </div>
                    </a>
                  </Tag>
                </div>
                <div className={styles.imgCardBrowser}>
                  <img alt="example" width="48%" height="65%" src={chromeImg} />
                  <Tag style={{ marginTop: '15px', width: '100px' }}>
                    <a
                      href={urlChrome}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                      <div className={styles.imgDownloadContainer}>
                        <DownloadIcon width="12px" />
                        Chrome
                      </div>
                    </a>
                  </Tag>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.imgBrowser}>
              <div className={styles.imgBrowserTwo}>
                <div className={styles.imgCardBrowser}>
                  <img alt="example" width="50%" height="71%" src={safariImg} />
                  <Tag style={{ marginTop: '15px', width: '100px' }}>
                    <a
                      href={urlSafari}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                      <div className={styles.imgDownloadContainer}>
                        <DownloadIcon width="12px" />
                        Safari
                      </div>
                    </a>
                  </Tag>
                </div>
                <div className={styles.imgCardBrowser}>
                  <img alt="example" width="50%" height="67%" src={chromeImg} />
                  <Tag style={{ marginTop: '15px', width: '100px' }}>
                    <a
                      href={urlChrome}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                      <div className={styles.imgDownloadContainer}>
                        <DownloadIcon width="12px" />
                        Chrome
                      </div>
                    </a>
                  </Tag>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {browserObj.so === 'iOS' ? (
            <div className={styles.imgBrowser}>
              <div className={styles.imgCardBrowser}>
                <img alt="mobileIOS" width="30%" src={safariImg} />
                <Tag style={{ marginTop: '15px', width: '100px' }}>
                  <div style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                    <div className={styles.imgDownloadContainer}>Safari</div>
                  </div>
                </Tag>
              </div>
            </div>
          ) : (
            <div className={styles.imgBrowser}>
              <div className={styles.imgCardBrowser}>
                <img alt="example" width="30%" height="67%" src={chromeImg} />
                <Tag style={{ marginTop: '15px', width: '100px' }}>
                  <a
                    href={urlChrome}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ fontWeight: 'bold', color: '#6B7CFF' }}>
                    <div className={styles.imgDownloadContainer}>
                      <DownloadIcon width="12px" />
                      Chrome
                    </div>
                  </a>
                </Tag>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ImgBrowserInfo
