import { notification } from 'antd'

const openNotification = placement => {
    notification.warning({
    message: `Advertencia!`,
    description: 'Alcanzaste el límite de 5 archivos',
    placement,
    })
}

const UploadFileHandler = (dataUploadsList, inputFileRef) => {
    if (dataUploadsList && dataUploadsList.uploads.length < 5) inputFileRef.current.click()
    else openNotification('bottomLeft')
}

export default UploadFileHandler