import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AppLayout from './AppLayout'
import AppointmentScreen from './AppointmentScreen'
import WelcomeScreen from './WelcomeScreen'
import LobbyScreen from './LobbyScreen'
import BrowserCompatibility from './BrowserCompatibility'
import UnsupportedBrowserScreen from './UnsupportedBrowserScreen'
import AblyCardPopUp from './AblyCardPopUp'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)
ReactGA.pageview(window.location.pathname + window.location.search)

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

const Screens = () => {
  return (
    <BrowserCompatibility>
      <AblyCardPopUp>
        <Switch>
          <AppLayoutRoute exact path="/" component={WelcomeScreen} />
          <Route
            path="/unsupported-browser"
            component={UnsupportedBrowserScreen}
          />
          <AppLayoutRoute
            path="/appointment/:token"
            component={AppointmentScreen}
          />
          <AppLayoutRoute path="/:token" component={LobbyScreen} />
          <Route component={() => <h1>404</h1>} />
        </Switch>
      </AblyCardPopUp>
    </BrowserCompatibility>
  )
}

export default Screens
