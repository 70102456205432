import { gql } from 'apollo-boost'

const GET_APPOINTMENTS = gql`
  query getAppointment($token: String!) {
    appointment(token: $token) {
      _id
      professional {
        _id
        firstname
        lastname
      }
      status
      patient {
        _id
        firstname
        lastname
      }
      review {
        patient {
          rating
        }
      }
      room
      code
      year
      month
      day
      hour
      minute
      scheduledAt
      confirmedAt
      clcAppointmentId
    }
  }
`

const CONFIRM_APPOINTMENT = gql`
  mutation ConfirmAppointment($_id: ID!) {
    confirmAppointment(_id: $_id) {
      _id
      confirmedAt
    }
  }
`

export { GET_APPOINTMENTS, CONFIRM_APPOINTMENT }
