import React from 'react'
import { useQuery } from 'react-apollo'
import { Link } from 'react-router-dom'

import { Spin } from 'antd'

import { GET_UPLOAD_FETCH_URL } from 'App/Queries'

const GetUploadFetchUrl = ({ url, filename }) => {

    const {
        data,
        loading,
        error
    } = useQuery(GET_UPLOAD_FETCH_URL, {
        variables: { key: url }
    })

    if(data)
        return <Link
        to={{ pathname: data.uploadFetchUrl }}
        target="_blank"
        rel="noopener noreferrer">
        {filename}
      </Link>

    if(loading) return <Spin/>

    if(error) return 'Error'

}

export default GetUploadFetchUrl