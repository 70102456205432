import React from 'react'
import { Spin, Popconfirm } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import AttachmentIcon from 'App/Assets/SVG/AttachmentIcon'

import styles from 'App/Screens/AppointmentScreen/Components/Common/FilesCard.module.css'

import { GetUploadFetchUrl } from 'App/Services/FilesManagementService/Services'

const DeleteFile = ({
  url,
  getUrlForDelete,
}) => {
  return (
    <Popconfirm
      title="¿Estas seguro?"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      okText="Aceptar"
      cancelText="Cancelar"
      onConfirm={() => {
        getUrlForDelete({ variables: { key: url } })
      }}>
      <DeleteOutlined />
    </Popconfirm>
  )
}

const Files = ({
  url,
  filename,
  getUrlForDelete
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <li className={styles.filesItems}>
        <AttachmentIcon width="13px" height="13px" fill="#CCCCCC" />
        <GetUploadFetchUrl url={url} filename={filename} />
      </li>
      <DeleteFile
        url={url}
        getUrlForDelete={getUrlForDelete}
      />
    </div>
  )
}

const FilesCard = ({
  dataUploadsList,
  loadingUploadsList,
  getUrlForDelete,
  processingFileService
}) => {

  return loadingUploadsList || processingFileService ? (
    <div className={styles.filesCardDrawer} style={{ textAlign: 'center' }}>
      <h3 className={styles.filesCardTitle}>
        {window.innerWidth > 392 ? 'Archivos cargados' : 'Archivos'}
      </h3>
      <div className={styles.filesContainer}>
        <Spin />
      </div>
    </div>
  ) : (
      <div className={styles.filesCardDrawer} style={{ textAlign: 'center' }}>
        <h3 className={styles.filesCardTitle} >
          {window.innerWidth > 392 ? 'Archivos cargados' : 'Archivos'}
        </h3>
        <div className={styles.filesContainer}>
          {dataUploadsList && dataUploadsList.uploads.length > 0 ? (
            dataUploadsList.uploads.map((elem, key) => {
              const arr = elem.key.split('/')
              const lastword = arr[arr.length - 1]
              const filename = lastword.slice(9, lastword.length)
              return (
                <Files
                  key={key}
                  url={elem.key}
                  filename={filename}
                  getUrlForDelete={getUrlForDelete}
                />
              )
            })
          ) : (
              <p>No hay archivos cargados</p>
            )}
        </div>
      </div>
    )
}

export default FilesCard
