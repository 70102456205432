import * as React from 'react'

function DownloadIcon(props) {
  return (
    <svg
      id="Flat"
      width={props.width}
      height={props.height}
      viewBox="0 0 512 512">
      <path
        d="m256 376a24 24 0 0 1 -24-24v-304a24 24 0 0 1 48 0v304a24 24 0 0 1 -24 24z"
        fill="#8690fa"
      />
      <g fill="#5153ff">
        <path d="m256 376a23.926 23.926 0 0 1 -16.971-7.029l-144-144a24 24 0 0 1 33.942-33.942l127.029 127.03 127.029-127.03a24 24 0 0 1 33.942 33.942l-144 144a23.926 23.926 0 0 1 -16.971 7.029z" />
        <path d="m464 488h-416a24 24 0 0 1 0-48h416a24 24 0 0 1 0 48z" />
      </g>
    </svg>
  )
}

export default DownloadIcon
