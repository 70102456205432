import * as React from 'react'

function InOtherAppointmentStatusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 1"
      width={props.width}
      height={props.height}
      stroke={props.stroke}
      fill={props.fill}
      strokeLinecap="round"
      {...props}>
      <line className="a" x2="7" transform="translate(0.5 0.5)" />
    </svg>
  )
}

export default InOtherAppointmentStatusIcon
