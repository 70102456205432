export default (realtime, channelName) => {
  const channel = realtime.channels.get(channelName)
  channel.attach(err => {
    if (err) return console.error('Could not attach to channel')
    channel.presence.unsubscribe()
    channel.presence.leave(err => {
      if (err) return console.error('Could not leave channel')
    })
  })
}
