import * as React from 'react'

function FinishedStatusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.414 6.414"
      width={props.width}
      height={props.height}
      stroke={props.stroke}
      fill={props.fill}
      strokeLinecap="round"
      {...props}>
      <line className="a" x1="5" y2="5" transform="translate(0.707 0.707)" />
    </svg>
  )
}

export default FinishedStatusIcon
