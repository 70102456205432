import React, { useState, useEffect, useCallback } from 'react'
import { Col, Row, Card } from 'antd'
import HeaderInfo from './Components/HeaderInfo'
import ImgBrowserInfo from './Components/ImgBrowserInfo'
import Clipboard from './Components/Clipboard'
import styles from './BrowserCompatibility.module.css'

const BrowserCompatibility = ({ children }) => {
  const [compatibilityBrowser, setCompatibilityBrowser] = useState(true)
  const [object, setObject] = useState()
  const [isDesktop, setIsDesktop] = useState(true)

  // Data
  const getDeviceSO = () => {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(window.navigator.platform) !== -1) os = 'Mac OS'
    else if (iosPlatforms.indexOf(window.navigator.platform) !== -1) os = 'iOS'
    else if (windowsPlatforms.indexOf(window.navigator.platform) !== -1)
      os = 'Windows'
    else if (/Android/.test(window.navigator.userAgent)) os = 'Android'
    else if (!os && /Linux/.test(window.navigator.platform)) os = 'Linux'

    return os
  }

  const getActualBrowser = useCallback(() => {
    const nvgAgent = navigator.userAgent
    const ngAgentLower = navigator.userAgent.toLowerCase()
    const { appVersion } = navigator
    let obj = { browser: '', so: '' }
    let browserVersion, versionStart, versionEnd

    // Detect Chrome
    let chromeAgent = nvgAgent.indexOf('Chrome') > -1
    // Detect Internet Explorer
    let IExplorerAgent =
      nvgAgent.indexOf('MSIE') > -1 || nvgAgent.indexOf('rv:') > -1
    // Detect Edge
    const edgeAgent = nvgAgent.indexOf('Edge') >= 0
    // Detect Firefox
    const firefoxAgent = nvgAgent.toLowerCase().indexOf('firefox') > -1
    // Detect Safari
    let safariAgent = nvgAgent.indexOf('Safari') > -1
    // Detect Opera
    const operaAgent = nvgAgent.indexOf('OP') > -1

    if (chromeAgent && safariAgent) safariAgent = false // chrome
    if (firefoxAgent && IExplorerAgent) IExplorerAgent = false // firefox
    if (chromeAgent && operaAgent) chromeAgent = false // opera
    if (edgeAgent && chromeAgent) chromeAgent = false // edge

    // response
    if (chromeAgent) {
      versionStart = ngAgentLower.indexOf('chrome') + 7
      versionEnd = ngAgentLower.indexOf(' ', versionStart)
      browserVersion = ngAgentLower.substring(versionStart, versionEnd)
      obj = { browser: 'Chrome', so: getDeviceSO(), version: browserVersion }
    }
    if (IExplorerAgent)
      obj = { browser: 'MSIE', so: getDeviceSO(), version: appVersion }
    if (edgeAgent)
      obj = { browser: 'Edge', so: getDeviceSO(), version: appVersion }
    if (firefoxAgent) {
      versionStart = ngAgentLower.indexOf('firefox') + 8
      versionEnd = ngAgentLower.indexOf(' ', versionStart)
      if (versionEnd === -1) versionEnd = ngAgentLower.length

      browserVersion = ngAgentLower.substring(versionStart, versionEnd)
      obj = { browser: 'Firefox', so: getDeviceSO(), version: browserVersion }
    }

    if (safariAgent) {
      const safariVersionMatch = nvgAgent.match(/version\/([\d.]+)/i)
      if (getDeviceSO() === 'iOS')
        if (navigator.userAgent.indexOf('CriOS') === -1) {
          if (navigator.userAgent.match('FxiOS'))
            // firefox ios
            obj = {
              browser: 'Firefox',
              so: getDeviceSO(),
              version: appVersion,
            }
          // safari ios
          else
            obj = {
              browser: 'Safari',
              so: getDeviceSO(),
              version: safariVersionMatch[1],
            }
        } else {
          versionStart = ngAgentLower.indexOf('chrome') + 7
          versionEnd = ngAgentLower.indexOf(' ', versionStart)
          browserVersion = ngAgentLower.substring(versionStart, versionEnd)
          obj = {
            browser: 'Chrome',
            so: getDeviceSO(),
            version: browserVersion,
          }
        }
      else
        obj = {
          browser: 'Safari',
          so: getDeviceSO(),
          version: safariVersionMatch[1],
        }
    }
    if (operaAgent)
      obj = { browser: 'Opera', so: getDeviceSO(), version: appVersion }
    if (
      !chromeAgent &&
      !IExplorerAgent &&
      !edgeAgent &&
      !firefoxAgent &&
      !safariAgent &&
      !operaAgent
    )
      obj = { browser: 'unknown', so: '' }

    return obj
  }, [])

  const typeOfDevice = soName => {
    const devices = {
      desktop: [
        {
          name: 'Mac OS',
        },
        {
          name: 'Windows',
        },
        {
          name: 'Linux',
        },
      ],
      mobile: [
        {
          name: 'Android',
        },
        {
          name: 'iOS',
        },
      ],
    }
    const isDesktop = devices.desktop.find(desktop => desktop.name === soName)
    if (isDesktop) return true
    else return false
  }

  const allowedBrowser = useCallback((type, browser, so, version) => {
    const usefullBrowsers = {
      desktop: [
        {
          name: 'Safari',
          version: 11,
        },
        {
          name: 'Chrome',
          version: 23,
        },
        {
          name: 'Firefox',
          version: 22,
        },
      ],
      mobile: [
        {
          name: 'Safari',
          version: 11,
        },
        {
          name: 'Chrome',
          version: 81,
        },
      ],
    }
    const correctBrowser = usefullBrowsers[type].find(
      obj => obj.name === browser,
    )
    if (correctBrowser)
      if (parseInt(version) >= correctBrowser.version) {
        if (so === 'iOS' && correctBrowser.name === 'Chrome') return false
        // when is iOS in Chrome get error
        return true // when is iOS in Safari pass
      } else return false
    else return false
  }, [])

  const checkBrowser = useCallback(
    actualBrowser => {
      const isDesktop = typeOfDevice(actualBrowser.so)
      if (isDesktop) {
        setIsDesktop(true)
        return allowedBrowser(
          'desktop',
          actualBrowser.browser,
          actualBrowser.so,
          actualBrowser.version,
        )
      } else {
        setIsDesktop(false)
        return allowedBrowser(
          'mobile',
          actualBrowser.browser,
          actualBrowser.so,
          actualBrowser.version,
        )
      }
    },
    [allowedBrowser],
  )

  useEffect(() => {
    const actualBrowser = getActualBrowser()
    if (actualBrowser) {
      setObject(actualBrowser)
      setCompatibilityBrowser(checkBrowser(actualBrowser))
    }
  }, [allowedBrowser, checkBrowser, getActualBrowser])

  return !compatibilityBrowser ? (
    <Row justify="center">
      <Col span={24} style={{ padding: '20px' }}>
        <div className={styles.cardContainerBrowsers}>
          <Card
            hoverable
            className={styles.cardBodyBrowsers}
            style={{ padding: isDesktop ? '30px' : '10px' }}
            cover={
              <>
                <HeaderInfo isDesktop={isDesktop} />
                <ImgBrowserInfo isDesktop={isDesktop} browserObj={object} />
              </>
            }>
            <Clipboard browserObj={object} />
          </Card>
        </div>
      </Col>
    </Row>
  ) : (
    <div>{children}</div>
  )
}

export default BrowserCompatibility
