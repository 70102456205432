import React from 'react'
const lobbySteps = [
  {
    selector: '[data-tut="lobby_0"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h1>Bienvenido </h1>
          <h3>Telemedicina Clínica las Condes.</h3>
          <div>
            {' '}
            A continuación te enseñaremos con unos simples pasos como funciona
            nuestra aplicación, presione la siguiente flecha para continuar.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_1"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tu Consulta</h3>
          <div>
            Conoce la hora de tu cita con tu doctor y su estado de conexión en
            la plataforma.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_2"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Adjuntar archivos</h3>
          <div>
            {' '}
            De ser necesario, acá podrás subir los exámenes o archivos que serán
            visualizados por tu{' '}
            <span style={{ fontWeight: 'bold' }}>doctor</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_3"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Micrófono</h3>
          <div>Desactiva o activa tú micrófono según necesites.</div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_4"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Tú Cámara</h3>
          <div>Desactiva o activa tú cámara según necesites.</div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_5"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Configuración</h3>
          <div>
            Escoge entre los Audios y Videos{' '}
            <span style={{ fontWeight: 'bold' }}>Disponibles</span>.
          </div>
        </div>
      )
    },
  },
  {
    selector: '[data-tut="lobby_6"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Ingresar a Consulta</h3>
          <div>
            Una vez testeada tú cámara y micrófono podras ingresar a tu{' '}
            <span style={{ fontWeight: 'bold' }}>Consulta</span>, en el momento
            que tu
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              Doctor se encuentre Conectado
            </span>
            .
          </div>
        </div>
      )
    },
  },
]

const lobbyDrawerSteps = [
  {
    selector: '[data-tut="lobbyDrawer_1"]',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h3>Sube tus archivos</h3>
          <div>
            Sube hasta un máximo de 5 archivos, estos serán visibles por el
            doctor en la <span style={{ fontWeight: 'bold' }}>Consulta</span>.
          </div>
        </div>
      )
    },
  },
]

export { lobbySteps, lobbyDrawerSteps }
