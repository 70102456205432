import * as React from 'react'

function CameraIcon(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 459 459"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}>
      <g>
        <g id="videocam">
          <path
            d="M357,191.25V102c0-15.3-10.2-25.5-25.5-25.5h-306C10.2,76.5,0,86.7,0,102v255c0,15.3,10.2,25.5,25.5,25.5h306
			c15.3,0,25.5-10.2,25.5-25.5v-89.25l102,102V89.25L357,191.25z"
          />
        </g>
      </g>
    </svg>
  )
}

export default CameraIcon
