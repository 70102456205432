import * as React from 'react'

function NextIcon(props) {
  return (
    <svg
      viewBox="0 0 429.92093 429.92093"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}>
      <path d="m366.960938 62.960938c-83.949219-83.949219-220.054688-83.949219-304 0-83.949219 83.945312-83.949219 220.050781 0 304 83.945312 83.945312 220.050781 83.945312 304 0 83.945312-83.949219 83.945312-220.054688 0-304zm-50.300782 159.097656-92.5 92.5c-1.875 1.890625-4.4375 2.9375-7.101562 2.902344-2.652344-.011719-5.195313-1.050782-7.097656-2.902344-1.871094-1.867188-2.925782-4.402344-2.925782-7.046875s1.054688-5.183594 2.925782-7.050781l75.5-75.5h-200.5c-5.523438 0-10-4.476563-10-10 0-5.523438 4.476562-10 10-10h200.5l-75.5-75.5c-3.894532-3.894532-3.894532-10.207032 0-14.101563 3.894531-3.890625 10.207031-3.890625 14.097656 0l92.5 92.5c3.828125 3.945313 3.875 10.203125.101562 14.199219zm0 0" />
    </svg>
  )
}

export default NextIcon
