import { createContext } from 'react'

const SettingsCall = createContext({
  settings: {
    audioInput: '',
    audioOutput: '',
    video: '',
    mobileVideo: '',
  },
})

export default SettingsCall
