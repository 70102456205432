import React, { Suspense } from 'react'

import useIsMobile from '../../Helpers/useIsMobile'

const DesktopIndexPage = React.lazy(() => import('./AppointmentScreenDesktop'))
const MobileIndexPage = React.lazy(() => import('./AppointmentScreenMobile'))

const AppointmentScreen = ({ history }) => {
  const isMobile = useIsMobile()

  const loading = <div style={{ height: '100%', width: '100%' }}></div>

  return (
    <Suspense fallback={loading}>
      {isMobile ? <MobileIndexPage /> : <DesktopIndexPage />}
    </Suspense>
  )
}

export default AppointmentScreen
