import * as React from 'react'

function WaitingStatusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5.213 7.106"
      width={props.width}
      height={props.height}
      stroke={props.stroke}
      fill={props.fill}
      strokeLinecap="round"
      {...props}>
      <g transform="translate(-28.894 -294)">
        <line className="a" y2="5" transform="translate(31.5 294.5)" />
        <line className="a" x1="4" y1="1" transform="translate(31.5 299.5)" />
      </g>
    </svg>
  )
}

export default WaitingStatusIcon
