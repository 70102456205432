import React from 'react'
import LobbyHeader from './LobbyHeader'
import styles from '../LobbyScreenStyles.module.css'
import { Row } from 'antd'
import Error from '../../../Assets/Images/Error.png'
const AppointmentNotFound = ({ windowDimensions }) => {
  return (
    <div className={styles.container}>
      <LobbyHeader windowDimensions={windowDimensions} />
      <Row
        gutter={24}
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '70vh' }}>
        <div className={styles.colNotFound}>
          <div className={styles.rowError}>
            <img src={Error} alt="error" className={styles.iconNotFound} />
            <p className={styles.errorText}>No se ha encontrado su consulta</p>
          </div>
          <p className={styles.footerText}>
            Para dudas y consultas contacte al correo
            info@clinicalascondes.cl
          </p>
        </div>
      </Row>
    </div>
  )
}

export default AppointmentNotFound
