import React, { createContext, useState, useEffect } from 'react'
import Ably from 'ably/promises'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import { GET_APPOINTMENTS } from '../../Queries'

export const AblyContext = createContext({
  realtime: null,
  stateConnection: null,
  enterChannels: [],
  channels: {},
  appointmentRoomId: {}
})

export const AblyProvider = ({ children }) => {
  const [realtime, setRealtime] = useState(null)
  const [enterChannels, setEnterChannels] = useState([])
  const [stateConnection, setStateConnection] = useState(null)
  const [appointmentRoomId, setAppoinmentRoomId] = useState(null)
  const [appointmentRoomName, setAppointmentRoomName] = useState(null)
  const [queueTestingChannelName, setQueueTestingChannelName] = useState(null)
  const [queueTestingChannelPS, setQueueTestingChannelPS] = useState(null)

  const [channels, setChannels] = useState({})
  const { pathname } = window.location
  const pathSplit = pathname.split('/')
  const token = pathSplit[pathSplit.length - 1]
  const { error, data } = useQuery(GET_APPOINTMENTS, {
    variables: { token: token && token !== '' && token },
  })

  if (error) console.log('no encontre token, me desconecto')

  const patientId = get(data, 'appointment.patient._id')

  useEffect(() => {
    const fx = async () => {
      enterChannels.forEach((item, key) => {
        const { channelName } = item

        const channelConnection = realtime.channels.get(channelName)

        channelConnection.attach(async err => {
          if (err) return console.error('Error attaching to channel')
          channelConnection.presence.enter('online', err => {
            if (err) return console.error('Failed to enter channel')
          })
          // channelConnection.presence.subscribe(async () => {

          setChannels(prevValue => ({
            ...prevValue,
            [channelName]: {
              connection: channelConnection,
            },
          }))
        })
      })
    }
    if (enterChannels.length > 0) fx()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterChannels])

  useEffect(() => {
    if (patientId && realtime === null)
      setRealtime(
        new Ably.Realtime.Promise({
          key: process.env.REACT_APP_ABLY_API_KEY,
          clientId: patientId,
        }),
      )
  }, [patientId, realtime, setRealtime])

  useEffect(() => {
    if (realtime !== null)
      realtime.connection.on(stateChange => {
        setStateConnection(stateChange.current)
      })
  }, [realtime])

  useEffect(() => {
    if (appointmentRoomName !== null && realtime !== null) {
      const channelName = `appointment:${appointmentRoomName}` || `${appointmentRoomName}`
      if (channels[channelName] !== undefined) {
        const channelConnection = channels[channelName].connection

        channelConnection.presence.subscribe(() => {
          channelConnection.presence.get(async (err, presenceSet) => {
            if (err) console.error('Failed to get current users')
            setAppoinmentRoomId(presenceSet)
          })
        })
      }
    }
  }, [appointmentRoomName, channels, realtime])

  useEffect(() => {
    if (queueTestingChannelName !== null && realtime !== null) {
      const channelName = `${queueTestingChannelName}`
      if (channels[channelName] !== undefined) {
        const channelConnection = channels[channelName].connection

        channelConnection.presence.subscribe(() => {
          channelConnection.presence.get(async (err, presenceSet) => {
            if (err) console.error('Failed to get current users')
            setQueueTestingChannelPS(presenceSet)
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueTestingChannelName, channels, realtime])

  const leavePresenceChannel = arrayChannels => {
    if (realtime !== null)
      return new Promise((resolve, reject) => {
        let counter = arrayChannels.length
        for (const channel in arrayChannels) {
          const channelConnection = realtime.channels.get(channel)
          // eslint-disable-next-line
          channelConnection.attach(err => {
            if (err) return console.error('Could not attach to channel')
            channelConnection.presence.leave(async err => {
              if (err) return console.error('Could not leave channel', err)
              delete channels[arrayChannels[channel]]
              counter -= 1
              if (counter === 0) {
                setChannels(channels)
                setEnterChannels([])
                resolve()
              }
            })
          })
        }
      })
    else return new Promise((resolve, reject) => resolve())
  }

  return (
    <AblyContext.Provider
      value={{
        realtime,
        stateConnection,
        channels,
        appointmentRoomId,
        queueTestingChannelPS,
        setAppointmentRoomName,
        setQueueTestingChannelName,
        leavePresenceChannel,
        setEnterChannels,
      }}>
      {children}
    </AblyContext.Provider>
  )
}
