
export default async (realtime, channelName, callback) => {
  const channel = await realtime.channels.get(channelName)
  let presence = null
  channel.attach (err => {
    if (err) return console.error('Error attaching to channel')
    channel.presence.enter('online', err => {
      if (err) return console.error('Failed to enter channel')
    })
    channel.presence.subscribe(async () => {
      await channel.presence.get((err, presenceSet) => {
        presence = presenceSet
        if (err) return console.error('Failed to get current users')
        callback && callback(presenceSet)
      })
    })
  })

  realtime.connection.on('failed', () => {
    console.error('Failed to join Ably')
  })
  return presence
}
