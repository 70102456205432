import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Screens from './Screens'
import Root from './Root'
import 'antd/dist/antd.css'
import 'typeface-roboto'
import 'typeface-inter'
import { ApolloApp, AblyProvider, SettingsCall, IntercomApp } from './Providers'
import { Client } from './ApolloClient'

export default function App() {
  return (
    <ApolloApp client={Client}>
      <AblyProvider>
        <SettingsCall.Provider
          value={{
            audioInput: '',
            audioOutput: '',
            video: '',
          }}>
          <Router>
          <IntercomApp>
            <Route component={Root}>{Screens}</Route>
          </IntercomApp>
          </Router>
        </SettingsCall.Provider>
      </AblyProvider>
    </ApolloApp>
  )
}
