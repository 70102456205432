import leavePresenceChannel from 'App/Helpers/leavePresenceChannel'
import { useEffect } from 'react'

const UseChannelsService = (
  ablyState,
  professionalId,
  appointmentRoom,
  appointment,
) => {
  const { realtime } = ablyState

  useEffect(() => {
    if (realtime)
      if (appointment && professionalId && appointmentRoom) {
        const { _id: patientId } = appointment.patient
        const { _id: appointmentId, room, status } = appointment
        if (status === 'PENDING' || status === 'ACTIVE') {
          const channelName = `queue_reactor_patient_${patientId}_${appointmentId}_${room}`
          ablyState.setAppointmentRoomName(`appointment:${appointmentRoom}`)
          ablyState.setEnterChannels([
            {
              channelName: `wait-room:${professionalId}`,
            },
            {
              channelName: `wait-room`,
            },
            {
              channelName: `appointment:${appointmentRoom}`,
            },
            {
              channelName,
            },
          ])
        } else
          leavePresenceChannel(realtime, `queue_reactor_patient_${patientId}_${appointmentId}_${room}`)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime, professionalId, appointmentRoom])

  // useEffect(() => {
  //   if (ablyState.realtime && ablyState.queueTestingChannelPS)
  //     ablyState.channels.queue_testing.connection.subscribe(
  //       'usuarios conectado ',
  //       {
  //         tipo: 'paciente',
  //       },
  //     )
  // }, [ablyState])
}

export default UseChannelsService
