import React, { useContext, useState, useEffect } from 'react'
import { AblyContext } from 'App/Providers'
import NetworkIcon from 'App/Assets/SVG/NetworkIcon'
import CheckIcon from 'App/Assets/SVG/CheckIcon'
import styles from './AblyCardPopUpStyles.module.css'

const AblyCardPopUp = ({ children }) => {
  const [translateState, setTranslateState] = useState(null)
  const [showCard, setShowCard] = useState(false)
  const [showConnected, setShowConnected] = useState(false)
  const ablyState = useContext(AblyContext)
  const { stateConnection } = ablyState

  useEffect(() => {
    if (stateConnection !== null)
      switch (stateConnection) {
        case 'connecting':
          setTranslateState('Reintentando...')
          break
        case 'connected':
          setShowConnected(true)
          setTranslateState('Estas Conectado')
          break
        case 'disconnected':
          setShowCard(true)
          setTranslateState('Problemas de Conexión')
          break
        case 'failed':
          setShowCard(true)
          setTranslateState('fallo conexión')
          break
        case 'closing':
          setShowCard(false)
          setTranslateState('Cerrando conexión')
          break
        case 'closed':
          setShowCard(false)
          setTranslateState('Conexión cerrada')
          break
        default:
          setShowCard(true)
          setTranslateState('Conexión Suspendida')
          return
      }
  }, [stateConnection])

  useEffect(() => {
    if (showConnected)
      setTimeout(() => {
        setShowConnected(false)
        setShowCard(false)
      }, 2000)
  }, [showConnected])

  return (
    <div className={styles.cardAblyContainer}>
      {children}
      {stateConnection !== null && showCard && (
        <div className={styles.cardAblyContent}>
          <div className={styles.cardAblyConnection}>
            {translateState}
            {stateConnection === 'connected' && (
              <div className={styles.cardAblyPulseIconConnected}>
                <CheckIcon width="18px" height="18px" fill="#52c41a" />
              </div>
            )}
            {(stateConnection === 'disconnected' ||
              stateConnection === 'suspended') && (
              <div className={styles.cardAblyPulseIconDisconnect}>
                <NetworkIcon width="18px" height="18px" fill="#39396a" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AblyCardPopUp
