import React from 'react'
import Logo from '../../../Assets/SVG/Logo'
import styles from '../BrowserCompatibility.module.css'

const HeaderInfo = ({ isDesktop }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0',
        }}>
        <div style={{ width: '45%', padding: '0px 0px 35px 0px' }}>
          <Logo />
        </div>
        <h1 className={styles.headerInfoBrowser}>Ups! </h1>
        <h4 className={styles.textInfoBrowser}>
          {isDesktop ? (
            <>
              Lo sentimos,{' '}
              <span style={{ fontWeight: 'bold' }}>
                tu navegador no es compatible
              </span>
              . Por favor utiliza uno de los siguientes navegadores
              <span style={{ fontWeight: 'bold' }}>
                en su versión más reciente.
              </span>
            </>
          ) : (
            <>
              Lo sentimos,{' '}
              <span style={{ fontWeight: 'bold' }}>
                tu navegador no es compatible
              </span>
              . Por favor utiliza el siguiente navegador{' '}
              <span style={{ fontWeight: 'bold' }}>
                en su versión más reciente.
              </span>
            </>
          )}
        </h4>
      </div>
    </>
  )
}
export default HeaderInfo
