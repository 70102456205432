import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import dayjs from 'dayjs'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

dayjs.locale('es')

const dsn = 'https://aac7d6946a6f462d8344f06d908e30de@o342603.ingest.sentry.io/5204724'
const environment = process.env.REACT_APP_BRANCH || 'localhost'
const release = `clc-app-patient@${process.env.REACT_APP_COMMIT_REF}`
const debug = !!process.env.REACT_APP_BRANCH === false

Sentry.init({
  dsn,
  environment,
  release,
  debug
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
