import React, { useRef } from 'react'

import { Row, Col } from 'antd'
import { FileDrop } from 'react-file-drop'

import { UploadFileHandler, DropUploadFileHandler } from './Handlers'

import { FilesManagementService } from 'App/Services'

import FileDropFilesManagement from 'App/Screens/LobbyScreen/Components/FileDropFilesManagement'
import FilesCard from 'App/Screens/AppointmentScreen/Components/Common/FilesCard'
import { ProgressBar } from 'App/GlobalComponents/DrawerFilesManagement/Components'

import styles from 'App/Screens/LobbyScreen/LobbyScreenStyles.module.css'

const DrawerFilesManagement = ({ appointment }) => {
  const inputFileRef = useRef()

  const {
    dataUploadsList,
    loadingUploadsList,
    errorUploadsList,
    refetchUploadsList,

    initFileUpload,
    initFileUploadOnDrop,
    uploadingFileService,
    processingFileService,
    uploadPercent,

    getUrlForDelete,
    dataGetUrlForDelete,
    errorGetUrlForDelete,
  } = FilesManagementService({
    appointment,
    inputFileRef,
  })

  return (
    <Row>
      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
        <FileDrop
          className={styles.fileDrop}
          onDrop={files => DropUploadFileHandler(initFileUploadOnDrop, files)}>
          <Row
            onClick={() =>
              !processingFileService &&
              UploadFileHandler(dataUploadsList, inputFileRef)
            }
            className={styles.fileDropRow}>
            <input
              type="file"
              hidden
              ref={inputFileRef}
              onChange={initFileUpload}
              id="files"
            />
            <FileDropFilesManagement
              appointment={appointment}
              uploadingFileService={uploadingFileService}
              processingFileService={processingFileService}
              loadingUploadsList={loadingUploadsList}
            />
          </Row>
          <Row>
            <Col span={24}>
              <ProgressBar
                uploadPercent={uploadPercent}
                uploadingFileService={uploadingFileService}
              />
            </Col>
          </Row>
        </FileDrop>
      </Col>
      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
        <FilesCard
          appointment={appointment}
          dataUploadsList={dataUploadsList}
          loadingUploadsList={loadingUploadsList}
          errorUploadsList={errorUploadsList}
          refetchUploadsList={refetchUploadsList}
          processingFileService={processingFileService}
          getUrlForDelete={getUrlForDelete}
          dataGetUrlForDelete={dataGetUrlForDelete}
          loadingGetUrlForDelete={loadingUploadsList}
          errorGetUrlForDelete={errorGetUrlForDelete}
        />
      </Col>
    </Row>
  )
}

export default DrawerFilesManagement
